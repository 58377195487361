import React, { useEffect, useState } from "react";
import { DealerApi } from "./Api";
import axios from "axios";
import "../Page/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CategoryComponent = ({ status, boxesData }) => {
  // const [boxesData, setBoxesData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [load, setLoad] = useState(true);
  const baseUrl = window.location.origin;

  // useEffect(() => {
  //   // setLoad(true);
  //   fetch(`${DealerApi}/category`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setLoad(true);
  //       setBoxesData(data.body.result);
  //     })
  //     .catch((error) => {
  //       setLoad(false);
  //     });
  // }, []);

  const handleClick = (index, slug) => {
    setSubCategories([]);
    status(true);
    setActiveIndex(index === activeIndex ? null : index);
    axios
      .get(`${DealerApi}/category/${slug}`)
      .then((response) => {
        setSubCategories(response.data.body.result);
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setActiveIndex(null);
    status(false);
  };

  const handleOptionCategory = (slug) => {
    localStorage.removeItem("searchFilterTheDealer");
    window.location.href = `${baseUrl}/${slug}`;
  };

  const handleSubCategory = (category, subcategory) => {
    localStorage.removeItem("searchFilterTheDealer");
    window.location.href = `${baseUrl}/${category}/${subcategory}`;
  };

  const renderBoxes = () => {
    return boxesData.map((box, index) => (
      <div
        key={index}
        className={`grid-box ${index === activeIndex ? "active" : ""}`}
        onClick={() => handleClick(index, box.slug)}
      >
        <div className="box_main_div">
          <img
            src={box.picture.url.small}
            alt={box.title}
            className="category_box_div"
          />
        </div>
        <p className="box_title">{box.category}</p>
        {index === activeIndex && subCategories && subCategories.length > 0 && (
          <div
            className="grid-box-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="content-text"
              onClick={() => handleOptionCategory(box.slug)}
            >
              <div>
                <img
                  src={box.picture.url.small}
                  alt={box.title}
                  className="image_border"
                />
              </div>
              <p className="box_title_main">
                <span>View all </span>
                {box.category}
              </p>
            </div>
            <div className="right_side_subcategoy_section">
              <div className="subcategory_main_div">
                {subCategories &&
                  subCategories.map((item) => {
                    return (
                      <p
                        className="subcategory_para"
                        onClick={() => handleSubCategory(box.slug, item.slug)}
                      >
                        {item.category}
                      </p>
                    );
                  })}
              </div>
              <div className="close-button">
                <button className="login_btn" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <>
      {/* <div className="load_img_div">
        {load && (
          <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
        )}
      </div> */}
      {/* {load ? ( */}
      {/* //   <div className="place_ad_div_section_loader">
      //   {load && ( */}
      {/* //     <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
      //   )} */}
      {/* // </div> */}
        {/* // ): */}
        {renderBoxes()}
      {/* // } */}
    </>
  );
};

export default CategoryComponent;