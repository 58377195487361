import React, { useState, useEffect, useRef } from "react";
import "./Paypal.css";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import Cookies from "js-cookie";
import Footer from "../Component/Footer";
import { useNavigate } from "react-router";
import { Alert } from "react-bootstrap";
import Heder from "../Component/Heder";

const Paypal = () => {

  const [value, setValue] = useState("3");
  const [paypalReady, setPaypalReady] = useState(false);
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const [clientId, setClientId] = useState([]);

  const amountRef = useRef("3");
  const navigate = useNavigate();


  useEffect(() => {
    axios.get(`${DealerApi}/keys`)
    .then((response) => {
      setClientId(response.data.body.result.paypal_api_key)
    })
    .catch((err)=> {
    })
  },[])

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "EUR",
            value: amountRef.current,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const token = Cookies.get("loginToken");
      const tokenObject = token && JSON.parse(token);

      const data = {
        user_id: tokenObject.id,
        txn_id: details.id,
        description: details,
        status: details.status,
        date_added: details.update_time,
        amount: details.purchase_units[0].amount.value,
      };

      axios
        .post(`${DealerApi}/payment`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${tokenObject.token}`,
          },
        })
        .then((res) => {
          setMessage(true);
          setError(false);
          setTimeout(() => {
            navigate("/client");
          }, 5000);
        })
        .catch((error) => {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 5000);
        });
    });
  };

  const onCancel = (data, actions) => {
    setError(true);
    setTimeout(()=> {
      setError(false);
    }, 5000)
  };

  useEffect(() => {
    if (clientId && clientId.client_id && clientId.paypal_currency_code) {
    const script = document.createElement("script");
    script.src =
      `https://www.paypal.com/sdk/js?currency=${clientId.paypal_currency_code}&client-id=${clientId.client_id}`;
    script.async = true;
    script.onload = () => setPaypalReady(true);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }
  }, [clientId]);

  useEffect(() => {
    if (paypalReady) {
      handleBuyCredit();
    }
  }, [paypalReady]);

  const handleBuyCredit = () => {
    if (paypalReady) {
      window.paypal
        .Buttons({
          createOrder: createOrder,
          onApprove: onApprove,
          onCancel: onCancel,
          onError: onCancel,
          style: {
            layout: "horizontal", 
            color: "gold", 
            shape: "rect", 
            tagline: false,
          },
        })
        .render("#paypal-container");
    }
  };

  return (
    <>
    <Heder name="paypal"/>
      <div className="container">
        <div className="payment_main_div">
          {message && (
            <div className="category_error_message">
              <Alert variant="success">Payment Success</Alert>
            </div>
          )}
          {error && <div className="category_error_message">
            <Alert variant="danger">Payment Failed</Alert>
          </div>}
          <div className="payment">
            <h1 className="payment_heading">Top Up with Paypal</h1>
            <p className="payment_text">
              Private users require a minimum of €3.00 to buy our digital
              edition. Business users require a minimum of €3.00 to place an
              advert.
            </p>
            <div className="credit_box">
              <label htmlFor="amount">€</label>
              <input
                type="text"
                name="amount"
                value={amountRef.current}
                maxLength="3"
                onChange={(e) => {
                  setValue(e.target.value);
                  amountRef.current = e.target.value;
                }}
              />
              <span className="zero">.00</span>
              <div className="paypal_main_div_button">
                <div id="paypal-container"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Paypal;