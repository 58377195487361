import React, { useEffect, useState } from "react";
import Footer from "../Component/Footer";
import ProductCard from "../Component/ProductCard";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import "./Home.css";
import Cookies from "js-cookie";
import CategoryComponent from "../Component/CategoryComponent";
import advertisement from "../Images/dealer-town-logo.jpg";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

const Home = () => {
  const [featured, setFeatured] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const [load, setLoad] = useState(true);
  const [boxesData, setBoxesData] = useState([]);
  // const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [keywords, setKeywords] = useState([]);

  document.title =
    "THE DEALER :: thedealer.ie :: buy, sell, exchange online in Ireland, Northern Ireland";

  useEffect(() => {
    axios
      .get(`${DealerApi}/settings`)
      .then((res) => {
        // setTitle(res.data.body.result[0].meta_title);
        setDescription(res.data.body.result[0].meta_keywords);
        setKeywords(res.data.body.result[0].meta_description);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setLoad(true);
    axios
      .get(`${DealerApi}/listing/featured`)
      .then((res) => {
        setLoad(false);
        setFeatured(res.data.body.result);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, []);

  const wishListFunction = async () => {
    if (tokenObject) {
      await axios
        .get(`${DealerApi}/wishlist/${tokenObject.id}`)
        .then((res) => {
          setWishlist(res.data.body.result.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    wishListFunction();
  }, [load]);

  const isInWishlist = (itemId) => {
    return wishlist && wishlist.some((item) => item.id === itemId);
  };

  const updateWishlist = () => {
    wishListFunction();
  };

  const handleStatus = (status) => {
    setShowModal(status);
  };

  useEffect(() => {
    // Check if screen width is less than or equal to 500px
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    handleResize(); // Check on initial render

    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on component unmount
    };
  }, []);

  useEffect(() => {
    if (isMobile && showModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [isMobile, showModal]);

  useEffect(() => {
    // setLoad(true);
    fetch(`${DealerApi}/category`)
      .then((response) => response.json())
      .then((data) => {
        // setLoad(false);
        setBoxesData(data.body.result);
      })
      .catch((error) => {
        // setLoad(false);
      });
  }, []);

  return (
    <>
    <Helmet>
        <title>THE DEALER :: thedealer.ie :: buy, sell, exchange online in Ireland, Northern Ireland</title>
        <meta
          name="description"
          content={`${description}`}
        />
        <meta
          name="keywords"
          content={`${keywords}`}
        />
      </Helmet>
      {isMobile && showModal && <div className="overlay" id="overlay"></div>}
      <div className="home_main_div">
        <div className="home_category_section">
          <div className="category_section container">
            <div className="category_content">
              <div className="grid-container">
                <CategoryComponent status={handleStatus} boxesData={boxesData}/>
              </div>

              <div className="place_add">
                <a href="mailto:sales@thedealer.ie">
                  <img
                    src={advertisement}
                    alt="logo"
                    className="advertisement_img_p"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="load_img_div">
          {load && <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />}
          </div>
          {featured && featured.length > 0 && (
            <div className="grid_section">
              <h2 className="featured_heading">Featured Listings</h2>
              <div className="featured_line"></div>
              <div className="product_grid">
                {featured.map((product) => {
                  return (
                    <ProductCard
                      key={product.id}
                      product={product}
                      wishlist={wishlist}
                      isInWishlist={isInWishlist(product.id)}
                      updateWishlist={updateWishlist}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;