import React from "react";
import { useNavigate, useParams } from "react-router";
import Footer from "../Component/Footer";
import "./StatusUpdate.css";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import Cookies from "js-cookie";
import Heder from "../Component/Heder";

const StatusUpdate = () => {
  const { id } = useParams();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const navigate = useNavigate();

  const handleActivate = () => {
    if (tokenObject) {
      axios
        .patch(
          `${DealerApi}/listing/change/status/confirm/${id}`,
          { status: "active" },
          {
            headers: {
              Authorization: `Bearer ${tokenObject.token}`,
            },
          }
        )
        .then((response) => {
          navigate("/my_online_ads");
        })
        .catch((error) => {});
    }
  };

  const handleAds = () => {
    navigate("/my_online_ads");
  }

  return (
    <>
    <Heder name="client"/>
      <div>
        <div className="faq_main_div">
          <p className="faq_heading">Activate Adverts</p>
        </div>
        <div className="container">
          <div className="status_update_div">
            <h4>Activate Your Ad For €3.00</h4>
            <p>
              For <strong>€3.00</strong> you can display your online-ad for the
              full <strong>4 months</strong> <br />
              Your ad will go straight to the top of our search results again.
            </p>
            <div className="status_button">
              <button className="login_btn" onClick={handleActivate}>
                Yes, activate my ad
              </button>
              <button className="login_btn" onClick={handleAds}>Return to my ads</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StatusUpdate;
