import React, { useEffect, useState } from "react";
import "./Wishlist.css";
import Footer from "../Component/Footer";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import Cookies from "js-cookie";
import CategoryProductCard from "../Component/CategoryProductCard";
import Pagination from "../Component/Pagination";
import { Alert } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Heder from "../Component/Heder";

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page")) || 1;

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState();
  const [message, setMessage] = useState(false);
  const [load, setLoad] = useState(true);

  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const upDatedWishlist = () => {
    setLoad(true);
    axios
      .get(
        `${DealerApi}/wishlist/${tokenObject.id}?per_page=${perPage}&page_no=${currentPage}`
      )
      .then((res) => {
        setLoad(false);
        if (res.data.body.message == "Wishlist Not Found!") {
          setMessage(true);
        }
        setWishlistData(res.data.body.result.data);
        const calculatedTotalPages = Math.ceil(
          res.data.body.result.total / perPage
        );
        setTotalPages(calculatedTotalPages);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  useEffect(() => {
    upDatedWishlist();
  }, [currentPage]);

  const updateWishlist = (newWishlist) => {
    setWishlist(newWishlist);
    upDatedWishlist();
  };

  useEffect(() => {
    if (currentPage !== initialPage) {
      setSearchParams({ page: currentPage });
    }
  }, [currentPage, initialPage, setSearchParams]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
    <Heder name="watchlist"/>
      <div className="advert_main_div">
        <p className="advert_heading">Watchlist</p>
      </div>

      <div className="wishlist_main_div">
        <div className="container">
          <div className="load_img_div">
            {load && (
              <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
            )}
          </div>
          <div className="wishlist_main">
            {message && (
              <div className="category_error_message">
                <Alert variant="primary">Wishlist Not Found</Alert>
              </div>
            )}
            {wishlistData && wishlistData.length > 0 && (
              <div className="grid">
                {wishlistData.map((product) => {
                  return (
                    <>
                      <CategoryProductCard
                        key={product.id}
                        product={product}
                        wishlist={wishlist}
                        updateWishlist={updateWishlist}
                        isInWishlist={"true"}
                      />
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Wishlist;
