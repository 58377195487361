import React, { useEffect } from "react";
import Footer from "./Footer";
import { useParams } from "react-router";
import axios from "axios";
import "./LoginVerify.css";
import { DealerApi } from "./Api";

const LoginVerify = () => {
  const { token } = useParams();

  useEffect(() => {
    axios
      .patch(`${DealerApi}/user/status/verify`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
      })
      .catch((error) => {
    });
  }, []);

  return (
    <>

      <div className="container">
        <div className="forgetpswd_main">
          <div className="login_verify_form">
            <h1 className="loginverify_heading">Email Verified</h1>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LoginVerify;