import React, { useEffect, useState } from "react";
import Footer from "../Component/Footer";
import "./About.css";
import user from "../Images/useravtar.png.jpg";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heder from "../Component/Heder";

const About = () => {

  const [description, setDescription] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    axios
      .get(`${DealerApi}/aboutus`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoad(false);
        setDescription(res.data.body.result.discription);
        setGallery(res.data.body.result.gallery);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, []);

  return (
    <>
    <Heder name="about"/>
      <div className="faq_main_div">
        <p className="faq_heading">ABOUT US</p>
      </div>
      <div className="container">
      <div className="load_img_div">
                {load && (
                 <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
                )}
              </div>
        <div className="about_heading_title">
          {description[0] && (
            <>
              <h4>{description[0].title}</h4>
              <p>{description[0].data}</p>
            </>
          )}
        </div>

        <div className="about_heading_title">
          {description[1] && (
            <>
              <h4>{description[1].title}</h4>
              <p>{description[1].data}</p>
            </>
          )}
        </div>

        <div className="about_heading_title">
          {description[2] && (
            <>
              <h4>{description[2].title}</h4>
              <div className="founder_grid">
                {description[2].data &&
                  description[2].data.map((item, index) => (
                    <div className="single_owner_grid" key={index}>
                      <img
                        src={item.image_url ? item.image_url : user}
                        alt="logo"
                        className="owner_img"
                      />
                      <div className="para_div">
                        <p>{item.name}</p>
                        <p>{item.designation[0]}</p>
                        <p>{item.designation[1]}</p>
                        <p>
                          <a
                            href={`mailto:${item.email}`}
                            className="mail_color"
                          >
                            {item.email}
                          </a>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>

        <div className="about_heading_title">
          {gallery && (
            <>
              <h4>{gallery.title}</h4>
              <div className="image_grid_about">
                {gallery.data &&
                  gallery.data.map((item, index) => (
                    <div className="single_image_grid" key={index}>
                      <img
                        src={item.image_url}
                        alt="img_random"
                        className="gallary_image"
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;