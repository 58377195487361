import React, { useEffect, useState } from "react";
import Footer from "../Component/Footer";
import Cookies from "js-cookie";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import { IoCreate } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Heder from "../Component/Heder";

const EditAccount = () => {
  const navigate = useNavigate();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const initialValues = {
    contact_name: tokenObject && tokenObject.contact_name,
    address_one: tokenObject && tokenObject.address_one,
    email: tokenObject && tokenObject.email,
    password: "",
    mobile: tokenObject && tokenObject.telephone,
    telephone: tokenObject && tokenObject.mobile,
    county_id: tokenObject && tokenObject.county_id,
    town_id: tokenObject && tokenObject.town_id,
    username: tokenObject && tokenObject.username,
    newsletter: true,
    postcode: "",
    confirmpassword: "",
    company_name: tokenObject && tokenObject.user_type,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [counties, setCounties] = useState([]);
  const [towns, setTowns] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const response = await axios.get(`${DealerApi}/county`);
        setCounties(response.data.body.result);
      } catch (error) {}
    };

    fetchCounties();
  }, []);

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const response = await axios.get(`${DealerApi}/county`);
        setCounties(response.data.body.result);
        if (tokenObject && tokenObject.county_id) {
          fetchTowns(tokenObject.county_id);
        }
      } catch (error) {}
    };

    fetchCounties();
  }, []);

  const fetchTowns = async (countyId) => {
    try {
      const response = await axios.get(`${DealerApi}/town/${countyId}`);
      setTowns(response.data.body.result);
    } catch (error) {}
  };

  const fieldTypes = {
    county_id: "number",
    town_id: "number",
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const dataType = fieldTypes[name];
    const updatedValue =
      (type === "checkbox"
        ? checked
        : type === "radio"
        ? e.target.value
        : value) && (dataType === "number" ? Number(value) : value);
    setFormValues({ ...formValues, [name]: updatedValue });
    if (name === "county_id") {
      fetchTowns(value);
    }
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    let errors = {};
    const regexNumber = /^[0-9]{1,20}$/;

    if (!formValues.contact_name) {
      errors.contact_name = "Full Name is required";
    }

    if (!formValues.company_name) {
      errors.company_name = "Company Name is required";
    }

    if (!formValues.address_one) {
      errors.address_one = "Address is required";
    }

    if (!formValues.email) {
      errors.email = "Email is required";
    }

    if (!formValues.telephone) {
      errors.telephone = "Primary Telephone is required";
    } else if (!regexNumber.test(formValues.telephone)) {
      errors.telephone = "Primary Telephone should have maximum length 20";
    }

    if (!formValues.address_one) {
      errors.address_one = "Address is required";
    }

    if (!formValues.county_id) {
      errors.county_id = "Please Select County";
    }

    if (!formValues.town_id) {
      errors.town_id = "Please Select Town";
    }

    if (!formValues.username) {
      errors.username = "Username is required";
    }

    if (formValues.password != formValues.confirmpassword) {
      errors.confirmpassword = "Confirm Password do not match";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      let data = {
        contact_name: formValues.contact_name,
        address_one: formValues.address_one,
        email: formValues.email,
        password: formValues.password,
        confirmpassword: formValues.confirmpassword,
        mobile: formValues.telephone,
        telephone: formValues.mobile,
        county_id: formValues.county_id,
        town_id: formValues.town_id,
        username: formValues.username,
        newsletter: formValues.newsletter,
        postcode: formValues.postcode,
        company_name: formValues.user_type,
      };
      axios
        .patch(`${DealerApi}/user/${tokenObject.id}`, data, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setFormValues(initialValues);
          if (res.status === 200) {
            const updatedTokenObject = {
              ...tokenObject,
              contact_name: formValues.contact_name,
              address_one: formValues.address_one,
              email: formValues.email,
              password: formValues.password,
              confirmpassword: formValues.confirmpassword,
              mobile: formValues.telephone,
              telephone: formValues.mobile,
              county_id: formValues.county_id,
              town_id: formValues.town_id,
              username: formValues.username,
              newsletter: formValues.newsletter,
              postcode: formValues.postcode,
              company_name: formValues.user_type,
            };
            Cookies.set("loginToken", JSON.stringify(updatedTokenObject));
            setError(null);
            setTimeout(() => {
              navigate("/client");
              setSuccess(null);
            }, 1000);
          }
        })
        .catch((error) => {});
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <>
    <Heder name="edit account"/>
      <div className="advert_main_div">
        <p className="advert_heading">Edit Your Account</p>
      </div>
      <div className="container">
        <div className="advert_main">
          <div className="advert_info">
            <div className="advert_form">
              <h1 className="place_ad_heading"></h1>

              <form onSubmit={handleSubmit}>
                <div className="place_ad_div">
                  <div className="place_ad_left_portion">
                    <label className="reg_heading company_name">Contact</label>
                    <label className="register_label company_name">
                      Full Name <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="contact_name"
                      value={formValues.contact_name}
                      onChange={handleChange}
                    />

                    <div className="error-message">
                      {formErrors.contact_name}
                    </div>

                    <label className="register_label company_name">
                      Main Phone <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="telephone"
                      value={formValues.telephone}
                      onChange={handleChange}
                    />

                    <div className="error-message">{formErrors.telephone}</div>

                    <label className="register_label company_name">
                      Secondary Phone
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="mobile"
                      value={formValues.mobile}
                      onChange={handleChange}
                    />

                    <label className="register_label company_name">
                      Email <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />

                    <div className="error-message">{formErrors.email}</div>

                    {tokenObject.user_type == "business" && (
                      <>
                        <label className="reg_heading company_name">
                          Business
                        </label>

                        <label className="register_label company_name">
                          Company Name <span className="astrick">*</span>
                        </label>
                        <input
                          type="text"
                          className="register_input"
                          name="company_name"
                          value={formValues.company_name}
                          onChange={handleChange}
                        />
                        <div className="error-message">
                          {formErrors.company_name}
                        </div>
                      </>
                    )}

                    <label className="reg_heading company_name">Location</label>

                    <label className="register_label company_name">
                      Address <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="address_one"
                      value={formValues.address_one}
                      onChange={handleChange}
                    />

                    <div className="error-message">
                      {formErrors.address_one}
                    </div>
                    <>
                      <label className="register_label">
                        County <span className="astrick">*</span>
                      </label>
                      <div className="price_div">
                        <select
                          name="county_id"
                          className="register_input"
                          onChange={handleChange}
                          value={formValues.county_id}
                        >
                          <option>Select County</option>
                          {counties.map((county) => (
                            <option key={county.id} value={county.id}>
                              {county.county}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="error-message">
                        {formErrors.county_id}
                      </div>

                      <label className="register_label">
                        Town <span className="astrick">*</span>
                      </label>
                      <div className="price_div">
                        <select
                          name="town_id"
                          className="register_input"
                          onChange={handleChange}
                          value={formValues.town_id}
                        >
                          <option>Select Town</option>
                          {towns.map((town) => (
                            <option key={town.id} value={town.id}>
                              {town.town}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="error-message">{formErrors.town_id}</div>

                      <label className="register_label company_name">
                        Postcode
                      </label>
                      <input
                        type="text"
                        className="register_input"
                        name="postcode"
                        value={formValues.postcode}
                        onChange={handleChange}
                      />
                      <div className="error-message">{formErrors.postcode}</div>

                      <label className="reg_heading company_name">Login</label>
                      <label className="register_label company_name">
                        Username <span className="astrick">*</span>
                      </label>
                      <input
                        type="text"
                        className="register_input"
                        name="username"
                        value={formValues.username}
                        onChange={handleChange}
                      />

                      <div className="error-message">{formErrors.username}</div>
                      <label className="reg_heading company_name">
                        Change Password
                      </label>
                      <label className="register_label company_name">
                        Enter New Password
                      </label>
                      <div className="password_input">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="register_input"
                        name="password"
                        value={formValues.password}
                        onChange={handleChange}
                      />
                      <div
                        className="password-toggle"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </div>
                      </div>
                      <div className="error-message">{formErrors.password}</div>

                      <label className="register_label company_name">
                        Confirm New Password
                      </label>
                      <div className="password_input">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="register_input"
                        name="confirmpassword"
                        value={formValues.confirmpassword}
                        onChange={handleChange}
                      />
                       <div
                        className="password-toggle"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </div>
                      </div>
                      <div className="error-message">
                        {formErrors.confirmpassword}
                      </div>
                    </>

                    <label className="register_newsletter company_name">
                      <input
                        type="checkbox"
                        name="newsletter"
                        value={formValues.newsletter}
                        onChange={handleChange}
                        checked={formValues.newsletter}
                      />
                      <span className="newsletter_word">
                        Subscribe to our Newsletter
                      </span>
                    </label>

                    <div className="submit_place_ad">
                      <button type="submit" className="create_account_btn">
                        Edit My Account
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="register_suggestion">
              <div className="registration_div">
                <h1 className="register_heading_info">
                  <div className="info_register">
                    <IoCreate className="place_icon" />
                    <p>Place An Add</p>
                  </div>
                </h1>
                <p>
                  Do you have something to sell, to rent, any service to offer?
                  Post it at TheDealer, its free, for local business and very
                  easy to use!
                </p>

                <h1 className="register_heading_info">
                  <div className="info_register">
                    <FaHeart className="place_icon" />
                    <p>Create your favorite listings list.</p>
                  </div>
                </h1>
                <p>
                  Create your favorite listings list. And save your search.
                  Don't forget any deal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditAccount;
