import React, { useEffect, useState } from "react";
import "./Payment.css";
import { useNavigate } from "react-router";
import paypal from "../Images/paypal.png";
import Footer from "../Component/Footer";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import Heder from "../Component/Heder";

const Payment = () => {
  const [balance, setBalance] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  useEffect(() => {
    if (tokenObject) {
      axios
        .get(`${DealerApi}/user/check/placeanadd/${tokenObject.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((res) => {
          if (res.data.body.result === false) {
            setMessage(res.data.body.message);
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (tokenObject) {
      axios
        .get(`${DealerApi}/user/account/check/${tokenObject.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((res) => {
          setBalance(res.data.body.result);
        })
        .catch((err) => {});
    }
  }, []);

  const handlePaypal = () => {
    navigate("/payments/paypal");
  };

  return (
    <>
    <Heder name="payments"/>
      <div className="container">
        <div className="payment_main_div">
          {message && (
            <div className="message-container">
              <Alert variant="warning">{message}</Alert>
            </div>
          )}
          <div className="payment">
            <h1 className="payment_heading">
              Your account balance is € {balance}
            </h1>
            <p className="payment_text">
              Classified Ads are free for private users. Business users require
              a minimum of €3.00 to place an advert.
            </p>
            <h2 className="buy_credit">Buy Credit</h2>
            <div className="payment_card">
              <button className="debit_card_btn" onClick={handlePaypal}>
                <img src={paypal} alt="paypal" className="debit_card_img" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Payment;
