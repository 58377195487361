import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  InputGroup,
  Nav,
  Navbar,
} from "react-bootstrap";
import logo from "../Images/logo.png";
import "./MainNavbar.css";
import { Link, useNavigate } from "react-router-dom";
import { RiPencilFill } from "react-icons/ri";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { MdLogin } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import Cookies from "js-cookie";
import { IoSearch } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { DealerApi } from "./Api";
import axios from "axios";

const MainNavbar = ({ loginStatus, onLogout }) => {
  const [showSearch, setShowSearch] = useState(true);
  const [query, setQuery] = useState("");
  const [balance, setBalance] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  useEffect(() => {
    if (tokenObject) {
      axios
        .get(`${DealerApi}/user/check/placeanadd/${tokenObject.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((res) => {
          setBalance(res.data.body.result);
          setMessage(res.data.body.message);
        })
        .catch((err) => {});
    }
  }, [tokenObject]);

  const handleSearch = () => {
    if (query) {
      if (location.pathname === `/search/search_lookfor`) {
        window.location.href = `/search/search_lookfor?q=${query}`;
      } else {
        setExpanded(false);
        navigate(`/search/search_lookfor?q=${query}`);
      }
      setQuery("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
      handleSearch();
    }
  };

  const handleLogout = () => {
    Cookies.remove("loginToken");
    Cookies.remove("listingToken");
    onLogout();
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleMouseDown = (event) => {
    if (event.button === 1) {
      event.preventDefault();
      Cookies.remove("listingToken");
      if (tokenObject && tokenObject.user_type == "admin") {
        window.open(
          `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
          "_blank"
        );
      } else if (tokenObject) {
        if (balance === true) {
          window.open("/online_ad");
        } else {
          window.open("/payments");
        }
      } else {
        window.open("/login");
      }
    }
  };

  const handlePlaceAnAd = (event) => {
    event.preventDefault();
    Cookies.remove("listingToken");
    if (tokenObject && tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
        "_self"
      );
    } else if (tokenObject) {
      if (balance === true) {
        navigate("/online_ad");
      } else {
        navigate("/payments", { state: { message } });
      }
    } else {
      navigate("/login");
    }
  };

  const handleNavClick = () => {
    setExpanded(false);
  };

  const handlelogo = () => {
    window.location.href = "/";
  };

  return (
    <Navbar collapseOnSelect expand="lg" expanded={expanded}>
      <Container>
        <div className="nav_bar_line">
          <Navbar.Brand onClick={handlelogo}>
            <img src={logo} alt="theDealer" className="logo" />
          </Navbar.Brand>
          <div className="d-lg-flex show_search">
            <InputGroup>
              <Form.Control
                type="search"
                placeholder="Search"
                value={query}
                onChange={handleInputChange}
                className="search_bar"
                aria-label="Search"
                onKeyDown={handleKeyDown}
              />
              <Button variant="light" onClick={handleSearch}>
                <IoSearch />
              </Button>
            </InputGroup>
          </div>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
        </div>

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={`justify-content-end ${showSearch ? "show-search" : ""}`}
        >
          <div id="google_translate_element" className="language_drop"></div>

          <Nav onClick={handleNavClick}>
            {loginStatus === false ? (
              <div className="d-lg-none reg_login_button">
                <Button
                  as={Link}
                  to="/register"
                  variant="secondary"
                  className="nav_link"
                >
                  Sign Up
                </Button>
                <Button
                  as={Link}
                  to="/login"
                  variant="dark"
                  className="nav_link"
                >
                  Login
                </Button>
              </div>
            ) : (
              <div className="d-lg-none reg_login_button">
                <Button
                  as={Link}
                  to="/client"
                  variant="secondary"
                  className="nav_link"
                >
                  {tokenObject && tokenObject.contact_name}
                </Button>
                <Button
                  as={Link}
                  to="/login"
                  variant="dark"
                  className="nav_link"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            )}

            <div className="d-lg-none reg_login_button">
              <div className="quick_link_div">
                <p className="quick_link_heading">Quick Links</p>
                <Nav.Link as={Link} to="/" className="quick_link">
                  HOME
                </Nav.Link>
                <Nav.Link as={Link} to="/faq" className="quick_link">
                  FAQ
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/terms_and_conditions"
                  className="quick_link"
                >
                  TERMS & CONDITIONS
                </Nav.Link>
                <Nav.Link as={Link} to="/contact" className="quick_link">
                  GET IN TOUCH
                </Nav.Link>
              </div>
            </div>

            <div className="d-none d-lg-flex">
              <div className="link_line"></div>
              <Nav.Link
                as={Link}
                onClick={handlePlaceAnAd}
                onMouseDown={handleMouseDown}
                className="nav_link"
              >
                <div className="navbar_btn">
                  <span>
                    <RiPencilFill />
                  </span>
                  <span>Place An Ad</span>
                </div>
              </Nav.Link>

              {loginStatus === false ? (
                <>
                  <div className="link_line"></div>
                  <Nav.Link as={Link} to="/register" className="nav_link">
                    <div className="navbar_btn">
                      <span>
                        <FaUserPlus />
                      </span>
                      <span>Sign Up</span>
                    </div>
                  </Nav.Link>

                  <div className="link_line"></div>
                  <Nav.Link as={Link} to="/login" className="nav_link">
                    <div className="navbar_btn">
                      <span>
                        <MdLogin />
                      </span>
                      <span>Login</span>
                    </div>
                  </Nav.Link>
                </>
              ) : (
                <>
                  <div className="link_line"></div>
                  <Nav.Link as={Link} to="/client" className="nav_link">
                    <div className="navbar_btn">
                      <span>
                        <FaUser />
                      </span>
                      <span className="user_name">
                        {tokenObject && tokenObject.contact_name}
                      </span>
                    </div>
                  </Nav.Link>

                  <div className="link_line"></div>
                  <Nav.Link as={Link} to="/login" className="nav_link">
                    <div className="navbar_btn" onClick={handleLogout}>
                      <span>
                        <IoIosLogOut />
                      </span>
                      <span>Logout</span>
                    </div>
                  </Nav.Link>
                </>
              )}
              <div className="link_line"></div>
            </div>
          </Nav>
        </Navbar.Collapse>

        <div className="d-lg-none search_input">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="Search"
              className="search_bar"
              aria-label="Search"
              value={query}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <Button variant="light" onClick={handleSearch}>
              <IoSearch />
            </Button>
          </InputGroup>
        </div>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
