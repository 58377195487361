import React, { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Breadcrumb,
  Dropdown,
  Offcanvas,
} from "react-bootstrap";
import Footer from "./Footer";
import { useLocation, useNavigate, useParams } from "react-router";
import { DealerApi } from "./Api";
import axios from "axios";
import CategoryProductCard from "./CategoryProductCard";
import Pagination from "./Pagination";
import { LuGrid } from "react-icons/lu";
import { LiaThListSolid } from "react-icons/lia";
import { Toast } from "react-bootstrap";
import "rc-slider/assets/index.css";
import { IoIosArrowForward } from "react-icons/io";
import CategoryListing from "./CategoryListing";
import "./CategoryPage.css";
import Cookies from "js-cookie";
import megaphone from "../Images/megaphone-icon.png";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heder from "./Heder";


const Search = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search_url = queryParams.get("q");
  const pageno = queryParams.get("page");
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [categories, setCategories] = useState([]);
  const { slug } = useParams();
  const [tittle, setTittle] = useState(slug);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(slug);

  const [currentPage, setCurrentPage] = useState((pageno === null || pageno === undefined || pageno === "")?1:parseInt(pageno));
  const [perPage, setPerPage] = useState(16);
  const [totalPages, setTotalPages] = useState();
  const [totalAds, setTotalAds] = useState();
  const [grid, setGrid] = useState(true);
  const [isGrid, setIsGrid] = useState(false);

  const [apicategory, setApiCategory] = useState(slug);
  const [apiSubCategory, setApiSubCategory] = useState("");

  const [subTitle, setSubTitle] = useState("");
  const [countyTitle, setCountyTitle] = useState("");

  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [sortingOption, setSortingOption] = useState("desc_score"); 

  const [cars, setCars] = useState(true);
  const [carsFilter, setCarsFilter] = useState(false);

  const [message, setMessage] = useState(null);
  const [messageShow, setMessageShow] = useState(false);

  const [error, setError] = useState(null);

  const [wishlist, setWishlist] = useState([]);
  const [load, setLoad] = useState(true);
  const [notFound, setNotFound] = useState(false);


  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const [isWishlistData, setIsWishlistData] = useState(false);

  const [toastMessage, setToastMessage] = useState("");

  const wishListFunction = async () => {
    if (tokenObject) {
      await axios
        .get(`${DealerApi}/wishlist/${tokenObject.id}`)
        .then((res) => {
          setIsWishlistData(res.data.body.result.data);
        })
        .catch((err) => {
        });
    }
  };

  useEffect(() => {
    wishListFunction();
  }, []);

  const isInWishlist = (itemId) => {
    return isWishlistData && isWishlistData.some((item) => item.id === itemId);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setProduct([]);
    setLoad(true);
    {
      search_url &&
        setTittle(`Search Results: ${capitalizeFirstLetter(search_url)}`);
    }
    {
      search_url &&
        setDynamicTitle(`Search Results: ${capitalizeFirstLetter(search_url)}`);
    }
    axios
      .get(
        `${DealerApi}/listing/search/word?search_word=${search_url}&per_page=${perPage}&page_no=${currentPage}&sorted_by=${sortingOption}`
      )
      .then((response) => {
        setLoad(false);
        setProduct(response.data.body.result.data);
        setTotalAds(response.data.body.result.total);
        setMessageShow(false);
        const calculatedTotalPages = Math.ceil(
          response.data.body.result.total / perPage
        );
        setTotalPages(calculatedTotalPages);
      })
      .catch((err) => {
        setLoad(false);
        if (
          err.response.data.body.message == "Listings not found"
        ) {
          setNotFound(true);
          setMessageShow(true);
          setError("No Data Found");
          setTotalAds(0);
        }
      });
  }, [perPage, currentPage, sortingOption]);

  useEffect(() => {
    axios
      .get(`${DealerApi}/category`)
      .then((data) => {
        let ans = data?.data?.body?.result.filter((index) => {
          return index.slug === slug;
        });
        setDynamicTitle(ans && ans[0].category);
        setTittle(ans && ans[0].category);
      })
      .catch((err) => {
      });
  }, []);

  const handleSortingChange = (label) => {
    setCurrentPage(1);
    navigate(
      `/search/search_lookfor?q=${search_url}&page=1`
    );
    switch (label) {
      case "relevance":
        setSortingOption("desc_score");
        break;
      case "mostRecent":
        setSortingOption("desc_date");
        break;
      case "lowestPrice":
        setSortingOption("asc_price");
        break;
      case "highestPrice":
        setSortingOption("desc_price");
        break;
      default:
        break;
    }
  };

  const toggleGrid = () => {
    setIsGrid(false);
    setGrid(true);
  };

  const toggleList = () => {
    setIsGrid(true);
    setGrid(false);
  };

  const handleShowPerPage = (value) => {
    setPerPage(value);
    setCurrentPage(1);
    navigate(
      `/search/search_lookfor?q=${search_url}&page=1`
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(
      `/search/search_lookfor?q=${search_url}&page=${page}`
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;

    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, currentPage]);

  const fetchCategory = async () => {
    await axios
      .get(`${DealerApi}/category`)
      .then((data) => {
        setCategories(data.data.body.result);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    fetchCategory();
  }, [slug, tittle]);

  useEffect(() => {
    fetchCategoryTitle(categories);
  }, []);

  const fetchCategoryTitle = (slug) => {
    const selectedCategory = categories.find(
      (category) => category.slug === slug
    );

    if (selectedCategory) {
      setTittle(selectedCategory.category);
      setDynamicTitle(selectedCategory.category);
    }
  };

  const fetchSubcategories = async (slug, title) => {
    setSelectedSubcategory(null);
    setCars(true);
    setCarsFilter(false);
    setApiCategory(slug);
    setApiSubCategory("");
    setSubTitle("");
    setCountyTitle("");
    setDynamicTitle(title);
    if (slug === selectedCategoryId) {
      setSelectedCategoryId(null);
    } else {
      axios
        .get(`${DealerApi}/category/${slug}`)
        .then((response) => {
          setCategories((prevCategories) =>
            prevCategories.map((category) =>
              category.slug === slug
                ? { ...category, subcategories: response.data.body.result }
                : category
            )
          );
          setSelectedCategoryId(slug);
          fetchCategoryTitle(slug);
        })
        .catch((error) => {
        });
      navigate(`/${slug}`);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchSubcategories(slug);
    }
  }, [slug]);

  const handleSubCategory = async (category) => {
    if (category.slug === "cars") {
      setCars(false);
      setCarsFilter(true);
    } else {
      setCars(true);
      setCarsFilter(false);
    }
    setSubTitle(category.category);
    setApiSubCategory(category.slug);
    setDynamicTitle(category.category);
    setSelectedSubcategory(category);
  };

  const updateWishlist = () => {
    wishListFunction();
  };

  const handleSaveSearch = () => {
    if (!tokenObject || !tokenObject.token || !tokenObject.id) {
      navigate("/login");
    } else {
      axios
        .post(
          `${DealerApi}/search/store`,
          {
            search_key_word: search_url,
            user_id: tokenObject && tokenObject.id,
            filters: {},
          },
          {
            headers: {
              Authorization: `Bearer ${tokenObject && tokenObject.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setToastMessage("Successfully save in search page");
          setTimeout(() => {
            setToastMessage("");
          }, 5000);
        })
        .catch((e) => {
        });
    }
  };

  return (
    <>
    <Heder name="Search Results"/>
      <div className="category_page_main">
        <div className="container">
          <div className="row">
            <Breadcrumb>
              <Breadcrumb.Item href="/" className="home_breadcrumb">
                Home
              </Breadcrumb.Item>
            </Breadcrumb>

            <div className="col-lg-3 col-md-4 d-lg-block aside">
              <div
                className={`product_sidebar pt-20 ${
                  showOffcanvas ? "d-none d-md-block d-sm-block" : "" 
                }`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <div className="sidebar_categories mt-10">
                      <div className="sidebar_title">
                        <Accordion.Header>
                          <h5 className="title">Categories</h5>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body className="accordion_body">
                        <div className="categories-container">
                          {categories.map((category) => (
                            <div key={category.id} className="category">
                              <p
                                onClick={() =>
                                  fetchSubcategories(
                                    category.slug,
                                    category.category
                                  )
                                }
                              >
                                <IoIosArrowForward /> {category.category}
                              </p>
                              {selectedCategoryId === category.slug &&
                                category.subcategories && (
                                  <ul className="subcategories">
                                    {category.subcategories.map(
                                      (subcategory) => (
                                        <li
                                          key={subcategory.id}
                                          onClick={() =>
                                            handleSubCategory(subcategory)
                                          }
                                        >
                                          {subcategory.category} (
                                          {subcategory.listing_count})
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                            </div>
                          ))}
                        </div>
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              <Toast
                show={toastMessage !== ""}
                onClose={() => setToastMessage("")}
                className="fade bg-success toast_message_div"
              >
                <Toast.Body className="toast_message">
                  {toastMessage}
                </Toast.Body>
              </Toast>
              <div className="ads_total_div">
                <h3>{dynamicTitle}</h3>

                {(totalAds == 0 || totalAds) && <h3>{totalAds} Ads</h3>}
              </div>

              {/* {/ Offcanvas Component /} */}
              <Offcanvas
                show={showOffcanvas}
                onHide={handleCloseOffcanvas}
                className="d-block d-lg-none"
                style={{ overflowY: "scroll" }}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <div className="sidebar_categories mt-10">
                        <div className="sidebar_title">
                          <Accordion.Header>
                            <h5 className="title">Categories</h5>
                          </Accordion.Header>
                        </div>
                        <Accordion.Body className="accordion_body">
                          <div className="categories-container">
                            {categories.map((category) => (
                              <div key={category.id} className="category">
                                <p
                                  onClick={() =>
                                    fetchSubcategories(
                                      category.slug,
                                      category.category
                                    )
                                  }
                                >
                                  <IoIosArrowForward /> {category.category}
                                </p>
                                {selectedCategoryId === category.slug &&
                                  category.subcategories && (
                                    <ul className="subcategories">
                                      {category.subcategories.map(
                                        (subcategory) => (
                                          <li
                                            key={subcategory.id}
                                            onClick={() =>
                                              handleSubCategory(subcategory)
                                            }
                                          >
                                            {subcategory.category} (
                                            {subcategory.listing_count})
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                              </div>
                            ))}
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="product_dropdown_main">
                <div className="product_dropdown">
                  <button
                    onClick={handleShowOffcanvas}
                    className="d-block d-lg-none filter_btn"
                  >
                    Filter
                  </button>

                  <Dropdown className="dropdown_page">
                    <Dropdown.Toggle
                      className="dropdown_page_main"
                      id="dropdown-basic"
                    >
                      Show {perPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleShowPerPage(16)}>
                        Show 16
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShowPerPage(32)}>
                        Show 32
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShowPerPage(60)}>
                        Show 60
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown className="dropdown_page">
                    <Dropdown.Toggle
                      className="dropdown_page_main"
                      id="dropdown-basic"
                    >
                      {sortingOption === "desc_score"
                        ? "Relavance"
                        : sortingOption === "desc_date"
                        ? "Most Recent"
                        : sortingOption === "asc_price"
                        ? "Lowest Price"
                        : sortingOption === "desc_price"
                        ? "Highest Price"
                        : ""}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleSortingChange("relevance")}
                      >
                        Relavance
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleSortingChange("mostRecent")}
                      >
                        Most Recent
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleSortingChange("lowestPrice")}
                      >
                        Lowest Price
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleSortingChange("highestPrice")}
                      >
                        Highest Price
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    onClick={handleSaveSearch}
                    className="save_search_btn"
                  >
                    Save Search Results
                  </button>
                </div>

                <div className="product_dropdown product_dropdown_grid">
                  <LuGrid
                    className={`grid_icon ${isGrid ? "active" : ""}`}
                    onClick={toggleGrid}
                  />

                  <LiaThListSolid
                    className={`list_icon ${!isGrid ? "active" : ""}`}
                    onClick={toggleList}
                  />
                </div>
              </div>

              {notFound && (
                  <div class="preview clicks">
                    <div class="image">
                    <img src={megaphone} alt="megaphone"/>
                    </div>
                    <h1>Sorry, no results found.</h1>
                    <p>We have nothing that matches your search. 
                      Browse all of our ads using the <a href={process.env.REACT_APP_API_REPORT} className="main_category">main categories</a>.</p>
                  </div>
              )}

              <div className="load_img_div">
                {(load && !error) && (
                  <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
                )}
              </div>
              {!load && !error && (
                <div>
                  <div className={`${grid ? "grid" : "list"}`}>
                    {messageShow && (
                      <p className="category_error_message">{message}</p>
                    )}
                    {grid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryProductCard
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                      })}
                    {isGrid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryListing
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              updateWishlist={updateWishlist}
                              isInWishlist={isInWishlist(product.id)}
                            />
                          </>
                        );
                      })}
                  </div>

                  {product && totalPages && totalPages > 1 && (
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Search;