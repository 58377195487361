import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductPage.css";

const ProductPage = ({ images, price, currency_sign }) => {
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const mainSlider = useRef(null);

  const handleMainImageChange = (index) => {
    setMainImageIndex(index);
  };

  const handleThumbnailClick = (index) => {
    setMainImageIndex(index);
    mainSlider.current.slickGoTo(index);
  };

  const mainImageSettings = (images && images.length>1)?{
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => handleMainImageChange(next),
    prevArrow: (
      <button className="slick-prev" aria-label="Previous" type="button"></button>
    ),
    nextArrow: (
      <button className="slick-next" aria-label="Next" type="button"></button>
    ),
  }:{
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => handleMainImageChange(next),
    arrows:false
  };

  if (images && images.length <= 1) {
    mainImageSettings.prevArrow = null;
    mainImageSettings.nextArrow = null;
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    prevArrow: (
      <button
        className="slick-prev"
        aria-label="Previous"
        type="button"
      ></button>
    ),
    nextArrow: (
      <button className="slick-next" aria-label="Next" type="button"></button>
    ),
  };

  const shouldRenderCarousel = images && images.length > 5;

  let thumbnailSection = null;
  let mainImageSection = images.map((image, index) => (
    <div key={index} className="main-image-wrapper">
      <img
        src={image.picture.url.large}
        alt={`Images ${index}`}
        className="main-image"
      />
    </div>
  ));

  if (images.length > 1) {
    thumbnailSection = shouldRenderCarousel ? (
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`thumbnail-wrapper ${
              mainImageIndex === index ? "selected" : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          >
            <img
              src={image.picture.url.small}
              alt={`Images ${index}`}
              className="thumbnail-image"
            />
            {mainImageIndex !== index && <div className="thumbnail-overlay" />}
          </div>
        ))}
      </Slider>
    ) : (
      images.map((image, index) => (
        <div
          key={index}
          className={`thumbnail-wrapper ${
            mainImageIndex === index ? "selected" : ""
          }`}
          onClick={() => handleThumbnailClick(index)}
        >
          <img
            src={image.picture.url.small}
            alt={`Images ${index}`}
            className="thumbnail-image"
          />
          {mainImageIndex !== index && <div className="thumbnail-overlay" />}
        </div>
      ))
    );
  }

  return (
    <div className="product-page-container">
      <div className="main-image-container">
             {images.length > 1 ? (
          <Slider {...mainImageSettings} ref={mainSlider}>
            {mainImageSection}
          </Slider>
        ) : (
          <>{mainImageSection}</>
        )}
        <span className="price1">
          {currency_sign} {price}
        </span>
      </div>
      <div className="thumbnail-container">{thumbnailSection}</div>
    </div>
  );
};

export default ProductPage;