import { Navigate, Route, Routes, useLocation} from "react-router-dom";
import Home from "./Page/Home";
import Register from "./Component/Register";
import Login from "./Component/Login";
import Faq from "./Page/Faq";
import TermsCondition from "./Page/TermsCondition";
import Contact from "./Page/Contact";
import About from "./Page/About";
import ForgetPswd from "./Component/ForgetPswd";
import CategoryPage from "./Component/CategoryPage";
import VerifyAccount from "./Component/VerifyAccount";
import LoginVerify from "./Component/LoginVerify";
import PrivateRoute from "./Component/PrivateRouting";
import ImageUpload from "./Component/ImageUpload";
import BusinessDirectory from "./Page/BusinessDirectory";
import Wishlist from "./Page/Wishlist";
import MyAd from "./Page/MyAd";
import EditAccount from "./Page/EditAccount";
import Dashboard from "./Page/Dashboard";
import Search from "./Component/Search";
import MainNavbar from "./Component/MainNavbar";
import OptionNavbar from "./Component/OptionNavbar";
import React, { useEffect, useState } from "react";
import SingleProduct from "./Component/SingleProduct";
import ReportPage from "./Component/ReportPage";
import PlaceAdd from "./Component/PlaceAdd";
import SavedSearch from "./Page/SavedSearch";
import BusinessDirectoryDetail from "./Page/BusinessDirectoryDetail";
import AdInactive from "./Page/AdInactive";
import BusinessDirectoryInfo from "./Page/BusinessDirectoryInfo";
import Paypal from "./Page/Paypal";
import Payment from "./Page/Payment";
import ScrollToTopOnPageChange from "./Component/ScrollToTopOnPageChange";
import CookiesPage from "./Component/Cookies";
import Cookies from "js-cookie";
import CategorySearch from "./Page/CategorySearch";
import CarSearch from "./Page/CarSearch";
import StatusUpdate from "./Page/StatusUpdate";
import axios from "axios";
import { DealerApi } from "./Component/Api";

function App() {

  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    axios
      .get(`${DealerApi}/settings`)
      .then((res) => {
        const analyticsCode = res.data.body.result[0].analytics_code;

        injectScripts(analyticsCode);
      })
      .catch((err) => {
      });
  }, []);

  const injectScripts = (analyticsCode) => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(analyticsCode, "text/html");

    const scriptTags = htmlDocument.querySelectorAll("script");

    scriptTags.forEach((scriptTag) => {
      const newScript = document.createElement("script");

      if (scriptTag.src) {
        newScript.src = scriptTag.src;
        newScript.async = true;
      } else {
        newScript.innerHTML = scriptTag.innerHTML;
      }

      document.head.appendChild(newScript);
    });

    const noscriptTags = htmlDocument.querySelectorAll("noscript");
    noscriptTags.forEach((noscriptTag) => {
      const newNoscript = document.createElement("noscript");
      newNoscript.innerHTML = noscriptTag.innerHTML;
      document.head.appendChild(newNoscript);
    });
  };

  useEffect(() => {
    const storedLoginStatus = Cookies.get("loginStatusTheDealer");
    if (storedLoginStatus) {
      setLoginStatus(JSON.parse(storedLoginStatus));
    }
  }, []);

  useEffect(() => {
    Cookies.set("loginStatusTheDealer", JSON.stringify(loginStatus));
  }, [loginStatus]);

  const handleLogin = () => {
    setLoginStatus(true);
  };

  const handleLogout = () => {
    setLoginStatus(false);
  };

  return (
    <>
      <div className="header_main">
        <MainNavbar loginStatus={loginStatus} onLogout={handleLogout} />
        <OptionNavbar />
      </div>
      <ScrollToTopOnPageChange />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/login/forgot-password" element={<ForgetPswd />} />
        <Route path="/verify_account" element={<VerifyAccount />} />
        <Route path="/active/:token" element={<LoginVerify />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms_and_conditions" element={<TermsCondition />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route path="/:category/:subcategory/:county/:adtype/:id/:slug" element={<SingleProduct />} />
        <Route
          path="/report_an_advert/:category/:subcategory/:county/:adtype/:id/:slug"
          element={<ReportPage />}
        />
        <Route
          path="/report_an_advert"
          element={<ReportPage />}
        />

        <Route
          path="/online_ad"
          element={
            <PrivateRoute>
              <PlaceAdd />
            </PrivateRoute>
          }
        />
        {/* <Route path="/online_ad/uploads" element={<PrivateRoute><ImageUpload /></PrivateRoute>} />
        <Route path="/online_ad/uploads/:listing_id" element={<PrivateRoute><ImageUpload /></PrivateRoute>} /> */}
        <Route path="/online_ad/uploads/:listing_id?" element={<PrivateRoute><ImageUpload /></PrivateRoute>} />

        <Route path="/search" element={<CategorySearch />} />
        <Route path="/search/all" element={<CategorySearch />} />
        <Route path="/:slug" element={<CategoryPage />} />
        <Route path="/:slug/:subcat" element={<CategoryPage />} />
        
        <Route path="/car_search" element={<CarSearch />} />

        <Route path="/search/search_lookfor" element={<Search />} />

        <Route path="/business_directory/search/s/all/location/:all" element={<BusinessDirectory />} />
        <Route path="/business_directory/*" element={<Navigate to="/business_directory/search/s/all/location/all" replace />} />

        <Route
          path="/my_online_ads"
          element={
            <PrivateRoute>
              <MyAd />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_online_ads/status/:id/inactive"
          element={
            <PrivateRoute>
              <StatusUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/watchlist"
          element={
            <PrivateRoute>
              <Wishlist />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit_account"
          element={
            <PrivateRoute>
              <EditAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/client"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/saved_search"
          element={
            <PrivateRoute>
              <SavedSearch />
            </PrivateRoute>
          }
        />
        <Route
          path="/business_directory/details/:id/:slug"
          element={<BusinessDirectoryDetail />}
        />
        <Route path="/ad_inactive" element={<AdInactive />} />
        <Route
          path="/business_directory/info"
          element={<BusinessDirectoryInfo />}
        />
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/payments/paypal"
          element={
            <PrivateRoute>
              <Paypal />
            </PrivateRoute>
          }
        />
      </Routes>

    </>
  );
}

export default App;