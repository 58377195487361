import React, { useEffect, useState } from "react";
import Footer from "../Component/Footer";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Alert, Nav, Table, Toast } from "react-bootstrap";
import { TbPhotoEdit } from "react-icons/tb";
import "./MyAd.css";
import { IoEye } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { HiRefresh } from "react-icons/hi";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import Pagination from "../Component/Pagination";
import { Link, useSearchParams } from "react-router-dom";
import ConfirmationDialog from "../Component/ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Heder from "../Component/Heder";

const MyAd = () => {
  const [data, setData] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [bumpSuccess, setBumpSuccess] = useState(false);
  const [bumpError, setBumpError] = useState(false);
  const [placeAdEdit, setPlaceAdEdit] = useState(false);
  const [balance, setBalance] = useState("");
  const [viewError, setViewError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page")) || 1;

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState();
  const [message, setMessage] = useState("");

  const [status, setStatus] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [load, setLoad] = useState(true);

  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const listing = Cookies.get("listingToken");
  const listingObject = listing && JSON.parse(listing);

  const navigate = useNavigate();

  useEffect(() => {
    if (tokenObject) {
      axios
        .get(`${DealerApi}/user/check/placeanadd/${tokenObject.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((res) => {
          setBalance(res.data.body.result);
          setMessage(res.data.body.message);
        })
        .catch((err) => {});
    }
  }, []);

  const toggleActions = (id) => (event) => {
    event.stopPropagation();
    setShowActions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getData = () => {
    setLoad(true);
    if (tokenObject) {
      axios
        .get(
          `${DealerApi}/listing/user/${tokenObject.id}?per_page=${perPage}&page_no=${currentPage}`
        )
        .then((res) => {
          if (res.data.body.result.data.length == 0) {
            setStatus(true);
            setTotalPages(0);
          } else {
            setLoad(false);
            setData(res.data.body.result.data);
            const calculatedTotalPages = Math.ceil(
              res.data.body.result.total / perPage
            );
            setTotalPages(calculatedTotalPages);
          }
        })
        .catch((err) => {
          if (err.response.data.body.success == 0) {
            setStatus(true);
            setLoad(false);
          }
        });
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  const handleRowClick = (item, status) => {
    if (status !== "inactive") {
      const urlText = convertToUrl(item.ad_title);
      const targetUrl = `/${item.category_slug}/${item.sub_category_slug}/${item.county}/${item.ad_type}/${item.id}/${urlText}`;
      window.location.href = targetUrl;
    } else {
      setViewError(true);
      setTimeout(() => {
        setViewError(false);
      }, 5000);
    }
  };

  const handleStatus = (item) => (event) => {
    event.stopPropagation();
    let status = item.status === "active" ? "inactive" : "active";
    if (tokenObject) {
      axios
        .patch(
          `${DealerApi}/listing/change/status/${item.id}`,
          { status },
          {
            headers: {
              Authorization: `Bearer ${tokenObject.token}`,
            },
          }
        )
        .then((response) => {
          if(response.data.body.result.redirect==true){
            navigate(`/my_online_ads/status/${item.id}/inactive`)
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {});
    }
  };

  const handleImage = (id) => (event) => {
    event.stopPropagation();
    let data = { listing_id: id };
    Cookies.set("listingToken", JSON.stringify(data));
    navigate(`/online_ad/uploads/${id}`);
  };

  const convertToUrl = (text) => {
    text = text.trim().toLowerCase();
    text = text.replace(/\s+/g, "-");
    text = text.replace(/(\d+)\.(\d+)/g, "$1-$2");
    text = text.replace(/[^a-z0-9-]/g, "");
    return text;
  };

  const handleView = (item, status) => (event) => {
    event.stopPropagation();
    if (status !== "inactive") {
      const urlText = convertToUrl(item.ad_title);
      const targetUrl = `/${item.category_slug}/${item.sub_category_slug}/${item.county}/${item.ad_type}/${item.id}/${urlText}`;
      window.open(targetUrl, '_blank');
      // window.location.href = targetUrl;
    } else {
      setViewError(true);
      setTimeout(() => {
        setViewError(false);
      }, 5000);
    }
  };

  const handleBump = (id) => (event) => {
    event.stopPropagation();
    setShowActions({});
    if (tokenObject) {
      axios
        .patch(`${DealerApi}/listing/bump/${id}`, null, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((response) => {
          getData();
          setBumpSuccess(true);
          setTimeout(() => {
            setBumpSuccess(false);
          }, 5000);
        })
        .catch((error) => {
          setBumpError(true);
          setTimeout(() => {
            setBumpError(false);
          }, 5000);
        });
    }
  };

  const handleEdit = (id) => (event) => {
    event.stopPropagation();
    setPlaceAdEdit(true);
    let data = { listing_id: id };
    Cookies.set("listingToken", JSON.stringify(data));
    navigate("/online_ad");
  };

  const handleDelete = (id) => (event) => {
    event.stopPropagation();
    setItemToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    setLoad(true);
    const id = itemToDelete;
    setShowModal(false);

    if (id == (listingObject && listingObject.listing_id)) {
      Cookies.remove("listingToken");
    }

    if (tokenObject) {
      axios
        .delete(`${DealerApi}/listing/${id}`, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((response) => {
          setLoad(false);
          setDeleteSuccess(true);
          if (data && data.length > 1) {
            getData();
          } else {
            window.location.reload();
          }
          setTimeout(() => {
            setDeleteSuccess(false);
          }, 5000);
        })
        .catch((error) => {
          setDeleteError(true);
          setTimeout(() => {
            setDeleteError(false);
          }, 5000);
        });
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setItemToDelete(null);
  };

  useEffect(() => {
    if (currentPage !== initialPage) {
      setSearchParams({ page: currentPage });
    }
  }, [currentPage, initialPage, setSearchParams]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      window.open("/online_ad");
    }
  };

  const handlePlaceAdBtn = (event) => {
    event.preventDefault();
    Cookies.remove("listingToken");
    if (tokenObject && tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
        "_self"
      );
    } else {
      if (balance === true) {
        navigate("/online_ad");
      } else {
        navigate("/payments", { state: { message } });
      }
    }
  };

  return (
    <>
    <Heder name="client"/>
      <div>
        <div className="faq_main_div">
          <p className="faq_heading">My Adverts</p>
        </div>

        <div className="container">
          <div className="myad_main">
            <Toast
              className="fade bg-success toast_message_div"
              show={bumpSuccess}
              onClose={() => setBumpSuccess(false)}
            >
              <Toast.Body className="toast_message">
                Listing bumped successfully!
              </Toast.Body>
            </Toast>

            <Toast
              show={bumpError}
              onClose={() => setBumpError(false)}
              className="fade bg-danger toast_message_div"
            >
              <Toast.Body className="toast_message">
                Error bumping the listing.
              </Toast.Body>
            </Toast>

            <Toast
              className="fade bg-success toast_message_div"
              show={deleteSuccess}
              onClose={() => setDeleteSuccess(false)}
            >
              <Toast.Body className="toast_message">
                Deleted successfully!
              </Toast.Body>
            </Toast>

            <Toast
              show={deleteError}
              onClose={() => setDeleteError(false)}
              className="fade bg-danger toast_message_div"
            >
              <Toast.Body className="toast_message">
                Error deleting the listing.
              </Toast.Body>
            </Toast>

            <Toast
              show={viewError}
              onClose={() => setViewError(false)}
              className="fade bg-danger toast_message_div"
            >
              <Toast.Body className="toast_message">
                This item is inactive and cannot be viewed.
              </Toast.Body>
            </Toast>

            <div className="my_ad_btn">
              <Nav.Link
                as={Link}
                onClick={handlePlaceAdBtn}
                onMouseDown={handleMouseDown}
              >
                <button className="login_btn">Place Ad</button>
              </Nav.Link>
              <Nav.Link as={Link} to="/client">
                <button className="login_btn">Dashboard</button>
              </Nav.Link>
            </div>
            <div className="load_img_div">
              {load && (
                <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
              )}
            </div>
            {status && (
              <div className="myad_error_message">
                <Alert variant="primary">Listings not found</Alert>
              </div>
            )}

            <div className="table_main">
              {data && data.length > 0 && (
                <Table responsive="md" striped>
                  <thead>
                    <tr>
                      <th className="action_res">Action</th>
                      <th className="photo">Photo</th>
                      <th className="title">Title</th>
                      <th className="category">Category</th>
                      <th className="category">County</th>
                      <th className="status">Ad Status</th>
                      <th className="price">Price</th>
                      <th className="action">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <React.Fragment key={item.id}>
                        <tr
                          onClick={() => handleRowClick(item, item.status)}
                          className="row_table"
                        >
                          <td className="arrow_button action_res">
                            <button className="icons_btn toggle-action ">
                              <IoMdArrowDropdown
                                className="icon_edit1"
                                title="Toggle Action"
                                onClick={(event) =>
                                  toggleActions(item.id)(event)
                                }
                              />
                            </button>
                          </td>
                          <td className="photo">
                            <div className="table_photo">
                              <img
                                src={item.picture.url.small}
                                alt="ads"
                                className="ad_image"
                              />
                            </div>
                          </td>
                          <td className="category">
                            <div className="table_category">
                              <h6 className="titles">{item.title}</h6>
                              <p className="item_day">
                                ({item.days_left} Days Left)
                              </p>
                            </div>
                          </td>
                          <td className="category">
                            <div className="table_category">
                              <p>{item.category}</p>
                            </div>
                          </td>

                          <td className="category">
                            <div className="table_category">
                              <p>{item.location}</p>
                            </div>
                          </td>
                          <td className="category">
                            <div className="table_category">
                              <button
                                className={
                                  // item.status == 0
                                  //   ? "active_btn_inactive"
                                  item.status == "active"
                                    ? "active_btn"
                                    : "active_btn_inactive"
                                }
                                onClick={(event) => handleStatus(item)(event)}
                                disabled={item.image_count == 0}
                                title={
                                  item.image_count == 0
                                    ? "To activate your advert, upload one image on advert."
                                    : ""
                                }
                              >
                                {/* {item.image_count == 0
                                  ? "Inactive" */}
                                {item.status == "active"
                                  ? "Active"
                                  : "Inactive"}
                              </button>
                            </div>
                          </td>
                          <td className="category">
                            <div className="table_category">
                              <p>{item.price ? item.price : "P.O.A"}</p>
                            </div>
                          </td>
                          <td className="action">
                            <div className="table_action">
                              <ul className="order_list">
                                <li>
                                  <button
                                    className="icons_btn"
                                    onClick={(event) =>
                                      handleImage(item.id)(event)
                                    }
                                  >
                                    <TbPhotoEdit
                                      className="icon_edit"
                                      title="Add Image"
                                    />
                                  </button>
                                </li>
                                <button
                                  className="icons_btn"
                                  onClick={(event) =>
                                    handleView(item, item.status)(event)
                                  }
                                >
                                  <li>
                                    <IoEye
                                      className="icon_edit"
                                      title="View Ad"
                                    />
                                  </li>
                                </button>
                                <button
                                  className="icons_btn"
                                  onClick={(event) =>
                                    handleBump(item.id)(event)
                                  }
                                >
                                  <li>
                                    <HiRefresh
                                      className="icon_edit"
                                      title="Bump Ad"
                                    />
                                  </li>
                                </button>
                                <button
                                  className="icons_btn"
                                  onClick={(event) =>
                                    handleEdit(item.id)(event)
                                  }
                                >
                                  <li>
                                    <FaEdit
                                      className="icon_edit"
                                      title="Edit Ad"
                                    />
                                  </li>
                                </button>
                                <button
                                  className="icons_btn"
                                  onClick={(event) =>
                                    handleDelete(item.id)(event)
                                  }
                                >
                                  <li>
                                    <MdDelete
                                      className="icon_edit"
                                      title="Delete Ad"
                                    />
                                  </li>
                                </button>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        {showActions[item.id] && (
                          <tr className="action_res">
                            <td className="action1" colSpan="6">
                              <ul className="order_list hidden_ordered_list">
                                <li>
                                  <button
                                    className="icons_btn"
                                    onClick={(event) =>
                                      handleImage(item.id)(event)
                                    }
                                  >
                                    <TbPhotoEdit
                                      className="icon_edit"
                                      title="Add Image"
                                    />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="icons_btn"
                                    onClick={(event) =>
                                      handleView(item, item.status)(event)
                                    }
                                  >
                                    <IoEye
                                      className="icon_edit"
                                      title="View Ad"
                                    />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="icons_btn"
                                    onClick={(event) =>
                                      handleBump(item.id)(event)
                                    }
                                  >
                                    <HiRefresh
                                      className="icon_edit"
                                      title="Bump Ad"
                                    />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="icons_btn"
                                    onClick={(event) =>
                                      handleEdit(item.id)(event)
                                    }
                                  >
                                    <FaEdit
                                      className="icon_edit"
                                      title="Edit Ad"
                                    />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="icons_btn"
                                    onClick={(event) =>
                                      handleDelete(item.id)(event)
                                    }
                                  >
                                    <MdDelete
                                      className="icon_edit"
                                      title="Delete Ad"
                                    />
                                  </button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
          <ConfirmationDialog
            show={showModal}
            handleClose={cancelDelete}
            handleConfirm={confirmDelete}
          />
          {totalPages > 1 && (
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MyAd;
