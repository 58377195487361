import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Footer from "../Component/Footer";
import Heder from "../Component/Heder";

const TermsCondition = () => {

  return (
    <>
    <Heder name="terms & conditions"/>
      <div className="faq_main_div">
        <p className="faq_heading">TERMS & CONDITIONS</p>
      </div>

      <div className="container">
        <div className="faq_div">
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>General</Accordion.Header>
              <Accordion.Body>
                This website is owned and operated by The Dealer “Free-Ads”
                Paper Ltd., Webtown House, Port Road, Letterkenny, Co Donegal.
                For the purpose of these terms and conditions "we", "our" and
                "us" refers to The Dealer “Free-Ads” Paper Ltd. Your use of this
                website is subject to our terms and conditions of use set out
                below ("Terms of Use"), and by accessing and using this website
                you are agreeing, and you are signifying your agreement, to be
                bound by these Terms of Use. If you do not read or agree to our
                Terms of Use, you should not access or use this website.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Changes in Terms & Conditions</Accordion.Header>
              <Accordion.Body>
                The Dealer “Free-Ads” Paper Ltd. may modify or terminate any
                facilities and/or services offered through this website from
                time to time, for any reason and without notice, and without
                liability to you, any other user or any third party. The Dealer
                “Free-Ads” Paper Ltd. reserves the right to change the content,
                presentation, performance, user facilities and/or availability
                of any part of this website at it’s sole discretion, including
                these Terms of Use from time to time. You should check these
                Terms of Use for any changes each time you access this website.
                Your continued use of the website will signify your acceptance
                of any revised Terms of Use.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="2">
              <Accordion.Header>Age and Responsibility</Accordion.Header>
              <Accordion.Body>
                If you are under the age of 16, you may only use this website in
                conjunction with and under the supervision of your parents or
                guardian. By using this website you understand that you are
                financially responsible for all use of this website by you and
                those using your log in information. You agree to notify us
                immediately of any unauthorised use or any other breach of
                security.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="3">
              <Accordion.Header>Use of Website</Accordion.Header>
              <Accordion.Body>
                <p>Unless otherwise specified, this website and any of it’s
                facilities and/or services are for your personal and
                non-commercial use only. You may download, display or print part
                of this website for your own personal, non-commercial use. You
                may save a copy of this page to your local hard disk for the
                purposes of creating one personal back-up copy. You agree not to
                copy, modify, transmit, display, distribute, perform, reproduce,
                license, publish, create derivative works from, transfer or sell
                any information, software, products and services contained on or
                forming part of this website, or otherwise use such content of
                this website for resale, re-distribution or for any other
                commercial use, including any surveys, contests or pyramid
                schemes, nor use the service to participate in or cause others
                to participate in sending chain letters, junk email, spam,
                duplicate or unsolicited communications.</p>
                <br />
                <p>You will not use this website or any of it’s facilities and/or
                services for any purpose that is unlawful or prohibited by these
                Terms of Use. You acknowledge that this website has been
                specifically designed for use in the Republic of Ireland and
                Northern Ireland, and you agree not to use or access it in and
                from jurisdictions in which it or it’s contents are restricted
                or prohibited by local law. You will not use this website in any
                manner which could damage, disable, overburden or impair this
                website, it’s facilities and/or services or interfere with any
                other party's use and/or enjoyment of this website, it’s
                facilities and/or services. You may not attempt to gain
                unauthorised access to this website, it’s facilities and/or
                services or any accounts, computer systems and networks
                connected to any of The Dealer “Free-Ads” Paper Ltd. websites,
                it’s facilities and/or services through hacking, password mining
                or any other means.</p>
                <br />
               <p> You agree not to use this website to harvest or otherwise
                collect by any means any program material or information
                (including without limitation email addresses or details of
                other users) from this website or as otherwise authorised under
                these Terms of Use or to monitor, mirror or copy any content of
                this website without the prior written consent of The Dealer
                “Free-Ads” Paper Ltd. Advertising of other websites through
                thedealer.ie will only be allowed with the express permission of
                The Dealer "Free-Ads" Paper Ltd. Such permission granted will be
                in writing.</p>
                <br />
                <p>The pages contained in this website may contain technical
                inaccuracies and typographical errors. The information in this
                website may be updated from time to time but we do not accept
                any responsibility for keeping the information in these pages
                up-to-date nor any liability for any failure to do so. The
                Dealer “Free-Ads” Paper Ltd. makes no representation as to the
                accuracy, reliability or completeness of the contents of this
                website or the advertisements posted on this website. The use of
                this website is at your own risk.</p>
                 <br />
                <p>The Dealer “Free-Ads” Paper Ltd. reserves the right, at it’s
                sole discretion, to pursue all of it’s legal remedies upon
                breach by you of these Terms of Use, including but not limited
                to deletion of your postings from this website, immediate
                termination of your registration and restricting your ability to
                access this website, or if The Dealer “Free-Ads” Paper Ltd. is
                unable to verify or authenticate any information you submit to
                this website.</p> <br />
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="4">
              <Accordion.Header>Registration</Accordion.Header>
              <Accordion.Body>
                <p>Registration is not required if you simply want to browse
                through the advertisements on this website. However,
                registration is required to place advertisements, save searches
                etc. In order to complete the registration you must choose a
                User ID and password. You will be asked to provide information
                such as your name, address, telephone number and email address
                in accordance with our GDPR policy. All information supplied
                must be complete and accurate. The Dealer “Free-Ads” Paper Ltd.
                may restrict the User ID or password you can choose.</p> 
                <br />
                <p>You are responsible for all actions taken under your account and
                you agree only to utilise this website using your own User ID
                and password. You must make reasonable efforts to keep your
                password safe and not disclose it to any other person or permit
                either directly or indirectly any other person to utilise your
                User ID or password. You agree to immediately notify The Dealer
                “Free-Ads” Paper Ltd. of any unauthorised use of your User ID
                and/or password. The Dealer “Free-Ads” Paper Ltd. can terminate
                your registration without prior notice at the discretion of The
                Dealer “Free-Ads” Paper Ltd.</p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="5">
              <Accordion.Header>Placing Ads</Accordion.Header>
              <Accordion.Body>
               <p> All advertisements offered for publication in The Dealer
                “Free-Ads” Paper and thedealer.ie must be legal, honest and
                truthful. They must comply with the Irish Code of Advertising
                Practice and advertising and editorial policies of The Dealer
                “Free-Ads” Paper Ltd. and are accepted for publication at it’s
                own absolute discretion. All advertisements may be edited and
                classified at the sole discretion of The Dealer “Free-Ads” Paper
                Ltd. All advertisements contained on this website are accepted
                for publication and published in good faith, but it is
                emphasised that The Dealer “Free-Ads” Paper Ltd. does not, in
                any circumstances, accept responsibility for the accuracy or
                otherwise of any advertisement or message published either
                online or off-line (nor is any kind of warranty expressed or
                implied by such publication).</p> <br />
                <p>All private, trade & business advertisements in The Dealer
                “Free-Ads” Paper publication or on this website - thedealer.ie
                must be prepaid where payment is required. Multiple items for
                sale in a single online classified advert, is not permitted. The
                Publisher cannot refund advertisers who wish to cancel their
                advertisement once their advertisement has run for one issue.
                All users are advised to check details of each advertisement
                carefully before entering into any agreements of any kind. If in
                doubt please seek legal advice.</p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="6">
              <Accordion.Header>Commercial Advertisers</Accordion.Header>
              <Accordion.Body>
                The Consumer Information Advertisements Disclosure of Business
                Interest Order 1984 requires all advertisements from persons
                selling goods in the course of a business to make that fact
                clear. This requirement applies where the advertiser is acting
                on their own behalf and/or for some other person in the course
                of their business. Employers (not agencies) advertising
                employment vacancies in the job section may do so free of
                charge. Multi-level marketing agents do not qualify for free
                ads. The Dealer “Free-Ads” Paper Ltd. may in it’s absolute
                discretion request whatever information or documentation it
                deems appropriate from an advertiser to verify that an
                advertiser is a bona fide employer and not an agency or
                multi-level marketing agency. We reserve the right to suspend
                the services offered to you by The Dealer “Free-Ads” Paper Ltd.
                if we have reasonable cause to suspect that you are in breach of
                any of the terms of use. Multiple items for sale in a single
                online classified advert, is not permitted.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="7">
              <Accordion.Header>Period of Advertisement</Accordion.Header>
              <Accordion.Body>
                Trade advertisements will remain active on the website for the
                time specified at the time of purchase. Private classified
                adverts will remain active for a period of 120 days unless
                modified by the user in terms of ‘Bump Up’, ‘Deactivation’ and
                ‘Reactivation’.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="8">
              <Accordion.Header>Suspension/Termination</Accordion.Header>
              <Accordion.Body>
                The Dealer “Free-Ads” Paper Ltd. may at any time, without notice
                to you, suspend or terminate your access to this website or any
                service forming part of this website, wholly or partially for
                any reason, including without limitation, where you are in
                breach of these Terms of Use, or if The Dealer “Free-Ads” Paper
                Ltd. cannot verify or authenticate any information submitted to
                the website, or if for any reason The Dealer “Free-Ads” Paper
                Ltd. suspends or discontinues the website or this service, or is
                unable to supply a service to you. The Dealer “Free-Ads” Paper
                Ltd. is not liable to you or any third party for any suspension
                or termination of access to this website.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                Copyright Notice & Limited Licence
              </Accordion.Header>
              <Accordion.Body>
                <p>The information, content, graphics, text, sounds, images,
                buttons, trademarks, service marks, trade names and logos (the
                "Materials") contained in this website are protected by
                copyright, trade mark, database right, sui generis right and
                other intellectual property laws, under national laws and
                international treaties.</p> <br />
                <p>The Dealer “Free-Ads” Paper Ltd. and/or its licensors (as the
                case may be) retain all right, title, interest and intellectual
                property rights in and to the materials. Any other use of the
                materials on this website, including in any form of copying or
                reproduction (for any purposes other than those noted above)
                modification, distribution, uploading, re-publication,
                extraction, re-utilisation, incorporation or integration with
                other materials or works or re-delivery using framing
                technology, without the prior written permission of The Dealer
                “Free-Ads” Paper Ltd. is strictly prohibited and is a violation
                of the proprietary rights of The Dealer “Free-Ads” Paper Ltd.
                Other than as expressly provided herein nothing in these Terms
                of Use should be construed as conferring by implication or
                otherwise any license or right under any copyright, patent,
                trade mark, database right, sui generis right or other
                intellectual property or proprietary interest of The Dealer
                “Free-Ads” Paper Ltd., it’s licensors or any third party.</p> <br />
                <p>You agree to grant The Dealer “Free-Ads” Paper Ltd. a
                non-exclusive, royalty free, world-wide, perpetual license with
                the right to sub-license, reproduce, distribute, transmit,
                create derivative works of, publicly display and publicly
                perform any materials and other information (including without
                limitation ideas contained therein for new or improved goods and
                services) you submit to any public areas of this website (such
                as bulletin boards, forms in news groups or by email to The
                Dealer “Free-Ads” Paper Ltd.). You also grant The Dealer
                “Free-Ads” Paper Ltd. the right to use your name in connection
                with the submitted content.</p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="10">
              <Accordion.Header>Indemnity</Accordion.Header>
              <Accordion.Body>
                You agree to defend, indemnify and hold The Dealer “Free-Ads”
                Paper Ltd. and (as applicable) it’s officers, directors,
                employees, agents, subsidiaries, affiliates, suppliers and any
                of our third party information service providers or other
                representatives harmless against any and all claims, demands,
                losses, expenses, damages and costs, including legal costs,
                however arising resulting from any violation or breach by you of
                these Terms of Use or any claims made by or liabilities to any
                third party resulting from any activities conducted under your
                account, your use or misuse of this website, including but not
                limited to posting content on this website, entering into
                transactions with other website users, contacting others as a
                result of their postings on this website, infringing any third
                party's intellectual property or other rights, or otherwise
                arising out of your breach of these Terms of Use.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="11">
              <Accordion.Header>Export Control</Accordion.Header>
              <Accordion.Body>
                You acknowledge that any goods or services sold to you under
                these terms of use may be subject to export control laws and
                regulations in jurisdictions including but not limited to the
                countries of the UK, US and the European Economic Area. You
                confirm that, should this be the case, you will not export or
                re-export such goods or services in breach of such laws or
                regulations.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="12">
              <Accordion.Header>Limited Dealings with Content</Accordion.Header>
              <Accordion.Body>
                <p>The Dealer “Free-Ads” Paper Ltd. acts as a passive conduit for
                the online distribution and publication of information and
                advertisements submitted by users, and has no obligation to
                screen advertisements or information in advance and is not
                responsible for screening or monitoring material posted by
                users. The Dealer “Free-Ads” Paper Ltd. does not exercise
                editorial control over the content of this website or other
                material created or accessible over or through this website.
                However, The Dealer “Free-Ads” Paper Ltd. retains the right to
                remove advertisements which are abusive, illegal or disruptive.
                The Dealer “Free-Ads” Paper Ltd. may take any action with
                respect to user submitted information that it deems necessary or
                appropriate in it’s sole discretion if it believes it may create
                liability for The Dealer “Free-Ads” Paper Ltd. The Dealer
                “Free-Ads” Paper Ltd. does not accept any responsibility for
                failure to contact an advertiser or to notify the advertisers of
                any changes to or rejection of advertisements which The Dealer
                “Free-Ads” Paper Ltd. deem necessary to comply with it’s
                production and editorial requirements, if such contact is not
                practical before the publication or due publication date.</p> <br />
                <p>The Dealer “Free-Ads” Paper Ltd. does not accept responsibility
                for the behaviour of users on the website or the information
                made available on this website by other users. Therefore, The
                Dealer “Free-Ads” Paper Ltd. does not guarantee or endorse the
                authenticity, quality, safety, or legality of any items offered
                or sold, the truth or accuracy of any adverts, or the ability of
                sellers to sell items.</p> <br />
                <p>The Dealer “Free-Ads” Paper Ltd. cannot guarantee that all
                transactions will be completed nor can we guarantee the ability
                or intent of users to fulfill their obligations in any
                transactions. If The Dealer “Free-Ads” Paper Ltd. is notified by
                a user of this website that certain communications do not
                conform to these Terms of Use, The Dealer “Free-Ads” Paper Ltd.
                may investigate the allegation and determine in good faith and
                at it’s sole discretion whether to remove or request the removal
                of the advertisement.</p> <br />
                <p>The Dealer “Free-Ads” Paper Ltd. has no liability or
                responsibility to users for the performance or non-performance
                of such activity. The Dealer “Free-Ads” Paper Ltd. reserves the
                right to expel users and to prevent their further access to this
                website for violations of these Terms of Use or the law. You
                agree to grant The Dealer “Free-Ads” Paper Ltd. a non-exclusive,
                royalty free, world-wide, perpetual license with the right to
                sub-license, reproduce, distribute, transmit, create derivative
                works of, publicly display and publicly perform any materials
                and other information (including without limitation ideas
                contained therein for new or improved goods and services) you
                submit to any public areas of this website (such as bulletin
                boards, forms in news groups or by email to The Dealer
                “Free-Ads” Paper Ltd.). You also grant The Dealer “Free-Ads”
                Paper Ltd. the right to use your name in connection with the
                submitted advertisements.</p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="13">
              <Accordion.Header>Links to Third Party Websites</Accordion.Header>
              <Accordion.Body>
                This website contains links to third party websites. Your use of
                third party websites is subject to the terms and conditions of
                use contained within each of those websites. Access to any other
                website through this website is at your own risk. The Dealer
                “Free-Ads” Paper Ltd. is not responsible or liable for the
                accuracy of any information, data, opinions, statements made on
                these websites or the security of any link or communication with
                those websites. The Dealer “Free-Ads” Paper Ltd. reserves the
                right to terminate a link to a third party website at any time.
                The fact that The Dealer “Free-Ads” Paper Ltd. provides a link
                to a third party website does not mean that The Dealer
                “Free-Ads” Paper Ltd. endorses, authorises or sponsors that
                website, nor does it mean that The Dealer “Free-Ads” Paper Ltd.
                is affiliated with the third party websites, owners or sponsors.
                The Dealer “Free-Ads” Paper Ltd. provides these links merely as
                a convenience for those who use this website.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="14">
              <Accordion.Header> Disclaimers </Accordion.Header>
              <Accordion.Body>
                <p>All use by you of this website (thedealer.ie) is at your own
                risk. You assume complete responsibility for, and for all risk
                of loss resulting from, your downloading and/or using of, or
                referring to or relying on facilities, services or materials
                provided on this website, or any other information obtained from
                your use of this website. You agree that The Dealer “Free-Ads”
                Paper Ltd. and providers of telecommunications and network
                services to The Dealer “Free-Ads” Paper Ltd. will not be liable
                for damages arising out of your use or your inability to use
                this website, and you hereby waive any and all claims with
                respect thereto, whether based on contract, tort or other
                grounds.</p> <br />
                <p>This website is available to all users "as is" and without any
                representations or warranties of any kind, either express or
                implied. The Dealer “Free-Ads” Paper Ltd. makes no
                representations, warranties or undertakings that this website,
                or the server that makes it available, will be free from
                defects, including, but not limited to viruses or other harmful
                elements. The Dealer “Free-Ads” Paper Ltd. accepts no liability
                for any infection by computer virus, bug, tampering,
                unauthorised access, intervention, alteration or use, fraud,
                theft, technical failure, error, omission, interruption,
                deletion, defect, delay, or any event or occurrence beyond the
                control of The Dealer “Free-Ads” Paper Ltd., which corrupts or
                effects the administration, security, fairness and the integrity
                or proper conduct of any aspect of this website.</p> <br />
                <p>The Dealer “Free-Ads” Paper Ltd. makes no representations,
                warranties or undertakings about any of the facilities, services
                and/or materials on this website (including without limitation,
                their accuracy, their completeness or their merchantability,
                quality or fitness for a particular purpose.</p> <br />
                <p>The security of your data is important to us but remember that
                no method of transmission over the internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your personal data, we
                cannot guarantee its absolute security. </p><br />
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="15">
              <Accordion.Header>Limitation of Liability </Accordion.Header>
              <Accordion.Body>
                <p>To the fullest extent permitted by applicable law, neither The
                Dealer “Free-Ads” Paper Ltd. or any of its officers, directors,
                employees, affiliates or other representatives will be liable
                for loss or damages arising out of or in connection with your
                use of any facilities, services and/or the materials offered or
                transactions entered into through or from this website or
                services through this website, including, but not limited to,
                indirect or consequential loss or damages, loss of data, loss of
                income, profit or opportunity, loss of or damage to property and
                claims of third parties, even if The Dealer “Free-Ads” Paper
                Ltd. has been advised of the possibility of such loss or
                damages, or such loss or damages were reasonably foreseeable.</p>
                <br />
                <p>In no event shall The Dealer “Free-Ads” Paper Ltd. nor any of
                its officers, directors, employees, affiliates or other
                representatives be liable for any damages whatsoever resulting
                from the statements or conduct of any third party or the
                interruption, suspension or termination of the services, whether
                such interruption, suspension or termination was justified or
                not, negligent or intentional, inadvertent or advertent. Without
                limiting the foregoing, under no circumstances shall The Dealer
                “Free-Ads” Paper Ltd. or any of its officers, directors,
                employees, affiliates or other representatives be held liable
                for any delay or failure in performance resulting directly or
                indirectly from acts of nature, forces or causes beyond its
                reasonable control, including, without limitation, internet
                failure, computer equipment failures, telecommunication
                failures, other equipment failures, electrical power failures,
                strikes, lay-way disputes, riots, interactions, civil
                disturbances, shortages of labour or materials, fires, floats,
                storms, explosions, acts of God, war, governmental actions,
                orders of domestic or foreign courts or tribunals,
                non-performance of third party.</p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="16">
              <Accordion.Header>Offline Conduct </Accordion.Header>
              <Accordion.Body>
                Although The Dealer “Free-Ads” Paper Ltd. cannot monitor the
                conduct of its users offline, it is a violation of these Terms
                of Use to use any information obtained from this website in
                order to harass, abuse or harm another person, or in order to
                contact, advertise to, solicit or sell to any user or person
                without their prior expressive consent.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="17">
              <Accordion.Header>Privacy Statement </Accordion.Header>
              <Accordion.Body>
                The Dealer “Free-Ads” Paper Ltd. and it’s associated companies
                respect and protect the privacy of the individuals, corporate
                bodies and societies who access this website and use it’s
                facilities and/or services. For full details of the manner in
                which The Dealer “Free-Ads” Paper Ltd. uses cookies, the type of
                information we collect, how we use it and under what
                circumstances we disclose information, please read the GDPR
                Privacy Policy, which is hereby incorporated into and forms part
                of these Terms of Use.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="18">
              <Accordion.Header>
                Jurisdiction and Governing Law{" "}
              </Accordion.Header>
              <Accordion.Body>
                <p>This website is controlled and operated by The Dealer “Free-Ads”
                Paper Ltd. from Ireland. The Dealer “Free-Ads” Paper Ltd. does
                not make any representation that the facilities, services and/or
                Materials offered through this website are appropriate or
                suitable for use in countries other than Ireland, or that they
                comply with any legal or regulatory requirements in any such
                other countries. In accessing this website, you do so at your
                own risk and on your own initiative, and are responsible for
                compliance with local laws, to the extent any local laws are
                applicable. If it is prohibited to make this website,
                facilities, services and/or Materials offered through this
                website or any part of them available in your country, or to you
                (whether by reason of nationality, residence or otherwise, this
                website, the facilities, services and/or Materials offered
                through this website or any part of them are not directed at
                you).</p> <br />
               <p> These Terms of Use shall be governed by and construed in
                accordance with the laws of Ireland, and you hereby agree for
                the benefit of The Dealer “Free-Ads” Paper Ltd. and without
                prejudice to the right of The Dealer “Free-Ads” Paper Ltd. to
                take proceedings in relation to these Terms of Use before any
                court of competent jurisdiction, that courts of Ireland shall
                have jurisdiction to hear and determine any actions or
                proceedings that may arise out of or in connection with these
                Terms of Use, or for such purposes you irrevocably summit to the
                jurisdiction of such courts.</p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="19">
              <Accordion.Header> Miscellaneous </Accordion.Header>
              <Accordion.Body>
                Any waiver of any provision of these Terms of Use must be in
                writing signed by The Dealer “Free-Ads” Paper Ltd. to be valid.
                Any waiver of any provision hereunder shall not operate as a
                waiver of any other provision, or a continuing waiver of any
                provision in the future. If any court of competent jurisdiction
                finds any provision of these Terms of Use to be void or
                unenforceable for any reason, then such provision shall be
                ineffective to the extent of the court's finding effecting the
                validity and enforceability of any remaining provision. These
                Terms of Use represent the entire understanding and agreement
                between you and The Dealer “Free-Ads” Paper Ltd. relating to use
                of this website, its facilities and/or services and supersede
                any and all prior statements, understandings and agreements
                whether oral or written, and shall not be modified except in
                writing, signed by you and by The Dealer “Free-Ads” Paper Ltd.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="20">
              <Accordion.Header>GDPR - Privacy Policy </Accordion.Header>
              <Accordion.Body>
                <h6>Introduction</h6>
                This General Data Protection Regulation (GDPR) Privacy Policy
                (the Policy) pertains to your rights on how The Dealer
                “Free-Ads” Paper Ltd. (our company), a company registered in
                Ireland, Registration No. 279445, collects and uses personal
                information. You have the right to review this Policy prior to
                providing The Dealer “Free-Ads” Paper Ltd. with any of your
                personal data. <br />
                <br />
                <h6>Topics:</h6>
                <p>What data do we collect?</p>
                <p>How do we collect your data?</p>
                <p>How will we use your data?</p>
                <p>How do we store your data?</p>
                <p>Marketing</p>
                <p>What are your data protection rights?</p>
                <p>What are cookies?</p>
                <p>How do we use cookies?</p>
                <p>What types of cookies do we use?</p>
                <p>How to manage your cookies</p>
                <p>Privacy policies of other websites</p>
                <p>Changes to our privacy policy</p>
                <p>How to contact us</p>
                <p>How to contact the appropriate authorities</p>
                <br />
                <h6>What data do we collect?</h6>
                <p>
                  The Dealer “Free-Ads” Paper Ltd. collects the following data:
                </p>
                <p>
                  Personal identification information - (Name, email address,
                  phone number, postal address etc.) in order to facilitate your
                  enquiry.
                </p>
                <p>Cookies and usage data.</p>
                <br />
                <h6>How do we collect your data?</h6>
                <p>
                  You directly provide our company with most of the data we
                  collect.
                </p>
                <p>We collect data and process data when you:</p>
                <p>
                  Register online or place an order for any of our products or
                  services.
                </p>
                <p>
                  Voluntarily complete a customer survey or provide feedback on
                  any of our message boards or via email.
                </p>
                <p>Use or view our website via your browser's cookies.</p>
                <br />
                <h6>How will we use your data?</h6>
                <p>Our company collects your data so that we can:</p>
                <p>Process your order and manage your account.</p>
                <p>
                  Email you with special offers on other products and services
                  we think you might like.
                </p>
                <p>
                  If you agree, our company will share your data with our sister
                  company Webtown Ltd. so that they may offer you their products
                  and services.
                </p>
                <p>
                  To allow you to participate in interactive features of our
                  Service when you choose to do so
                </p>
                <p>To provide customer care and support</p>
                <p>
                  To provide analysis or valuable information so that we can
                  improve the Service
                </p>
                <p>To monitor the usage of the Service</p>
                <p>To detect, prevent and address technical issues</p>
                <p>
                  We do not and will not disclose personal data to third
                  parties.
                </p>
                <br />
                <h6>How do we store your data?</h6>
                <p>
                  The Dealer “Free-Ads” Paper Ltd. securely stores your data at
                  our secure company premises at Port Road, Letterkenny, Co.
                  Donegal.
                </p>
                <p>
                  Digital information is stored on our secure dedicated servers
                  in Ireland and is protected by firewalls. Access is restricted
                  and password controlled.
                </p>
                <p>
                  We retain data that is required in our contractual duties in
                  the provision of services to the company and their employees
                  for as long as necessary.
                </p>
                <p>
                  Any deletion of data whether complete or partial, of the data
                  collected that is required to be carried out will be done when
                  retention is no longer necessary or at the request of the
                  owner of the data.
                </p>
                <br />
                <h6>Marketing</h6>
                <p>
                  The Dealer “Free-Ads” Paper Ltd. would like to send you
                  information about products and services of ours that we think
                  you might like, as well as those of our sister company Webtown
                  Ltd.
                </p>
                <p>
                  If you have agreed to receive marketing, you can always opt
                  out at a later date. You are under no obligation to respond to
                  these emails or texts.
                </p>
                <p>
                  You have the right at any time to stop our company from
                  contacting you for marketing purposes.
                </p>
                <br />
                <h6>What are your data protection rights?</h6>
                <p>
                  Our company would like to make sure you are fully aware of all
                  of your data protection rights. Every user is entitled to the
                  following:
                </p>
                <p>
                  <span>The right to access - </span>You have the right to
                  request The Dealer “Free-Ads” Paper Ltd. for copies of your
                  personal data. We may charge you a small fee for this service.
                </p>
                <p>
                  <span>The right to rectification -</span>You have the right to
                  request that our company correct any information you believe
                  is inaccurate. You also have the right to request our company
                  to complete information you believe is incomplete.
                </p>
                <p>
                  <span>The right to erasure - </span>You have the right to
                  request that our company erase your personal data, under
                  certain conditions.
                </p>
                <p>
                  <span>The right to restrict processing -</span>You have the
                  right to request that our company restrict the processing of
                  your personal data, under certain conditions.
                </p>
                <p>
                  <span>The right to object to processing -</span>You have the
                  right to object to our company's processing of your personal
                  data, under certain conditions.
                </p>
                <p>
                  <span>The right to data portability - </span> You have the
                  right to request that our company transfer the data that we
                  have collected to another organization, or directly to you,
                  under certain conditions.
                </p>
                <br />
                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us at our email: info@thedealer.ie Call us at: 00353 74
                  9126410 Or write to us: The Dealer “Free-Ads” Paper Ltd.,
                  Webtown House, Port Road, Letterkenny, Co. Donegal, F92 W5HX.
                </p>
                <br />
                <h6>What are cookies?</h6>
                <p>
                  Cookies are text files placed on your computer to collect
                  standard Internet log information and visitor behaviour
                  information. When you visit our websites, we may collect
                  information from you automatically through cookies or similar
                  technology such as “Usage Data”, your computer’s Internet
                  Protocol address (e.g. IP address), browser type, browser
                  version, the pages of our Service that you visit, the time and
                  date of your visit, the time spent on those pages, unique
                  device identifiers and other diagnostic data. For further
                  information, visit{" "}
                  <span>
                    {" "}
                    <a href="https://allaboutcookies.org/" target="_self">
                      allaboutcookies.org.
                    </a>
                  </span>
                </p>
                <br />
                <h6>How do we use cookies?</h6>
                <p>
                  Our company uses cookies in a range of ways to improve your
                  experience on our website, including:
                </p>
                <p>Keeping you signed in.</p>
                <p>Understanding how you use our website.</p>
                <br />
                <h6>What types of cookies do we use?</h6>
                <p>
                  There are a number of different types of cookies, however, our
                  website uses:
                </p>
                <p>
                  Functionality - Our company uses these cookies so that we
                  recognize you on our website and remember your previously
                  selected preferences. These could include what language you
                  prefer and location you are in. A mix of first-party and
                  third-party cookies are used.
                </p>
                <p>
                  Preference Cookies. We use Preference Cookies to remember your
                  preferences and various settings.
                </p>
                <p>
                  Security Cookies. We use Security Cookies for security
                  purposes.
                </p>
                <p>
                  Advertising - Our company may use these cookies to collect
                  information about your visit to our website, the content you
                  viewed, the links you followed and information about your
                  browser, device, and your IP address.
                </p>
                <br />
                <h6>How to manage cookies</h6>
                <p>
                  You can set your browser not to accept cookies, and the above
                  website tells you how to remove cookies from your browser.
                  However, in a few cases, some of our website features may not
                  function as a result.
                </p>
                <br />
                <h6>Privacy policies of other websites</h6>
                <p>
                  The Dealer “Free-Ads” Paper Ltd.’s website - thedealer.ie,
                  contains links to other websites. Our privacy policy applies
                  only to our website, so if you click on a link to another
                  website, you should read their privacy policy.
                </p>
                <br />
                <h6>Changes to our privacy policy</h6>
                <p>
                  Our company keeps it’s privacy policy under regular review and
                  places any updates on this web page. This privacy policy was
                  last updated on 8th March 2022.
                </p>
                <br />
                <h6>How to contact us</h6>
                <p>
                  If you have any questions about our company's privacy policy,
                  the data we hold on you, or you would like to exercise one of
                  your data protection rights, please do not hesitate to contact
                  us. Email us at: info@thedealer.ie Call us: 0035374 9126410 Or
                  write to us at: The Dealer “Free-Ads” Paper Ltd., Webtown
                  House, Port Road, Letterkenny, Co. Donegal, F92 W5HX.
                </p>
                <br />
                <h6>How to contact the appropriate authority </h6>
                <p>
                  Should you wish to report a complaint or if you feel that The
                  Dealer “Free-Ads” Paper Ltd. has not addressed your concern in
                  a satisfactory manner, you may contact the Information
                  Commissioner's Office. Email: info@oic.ie Address: The Office
                  of the Information Commissioner, 6 Earlsfort Terrace, Dublin
                  2, D02 W773.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
          
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsCondition;
