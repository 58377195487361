import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { DealerApi } from "./Api";
import axios from "axios";
import {
  Accordion,
  Alert,
  Breadcrumb,
  Carousel,
  Dropdown,
  Offcanvas,
} from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import Slider from "rc-slider";
import Footer from "./Footer";
import CategoryProductCard from "./CategoryProductCard";
import { LiaThListSolid } from "react-icons/lia";
import { LuGrid } from "react-icons/lu";
import Cookies from "js-cookie";
import CategoryListing from "./CategoryListing";
import Pagination from "./Pagination";
import { Toast } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import megaphone from "../Images/megaphone-icon.png";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CategoryPage = () => {
  const { slug, subcat } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [account, setAccount] = useState([]);
  const [county, setCounty] = useState([]);
  const [makes, setMakes] = useState([]);
  const [model, setModel] = useState([]);
  const [fuel, setFuel] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [colour, setColour] = useState([]);
  const [engineSize, setEngineSize] = useState([]);
  const [yearRange, setYearRange] = useState([]);
  const [firstYear, setFirstYear] = useState([]);

  const [priceRange, setPriceRange] = useState([]);
  const [firstPrice, setFirstPrice] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedFuel, setSelectedFuel] = useState("");
  const [selectedBodyTypes, setSelectedBodyTypes] = useState("");
  const [selectedColour, setSelectedColour] = useState("");
  const [selectedEngineSize, setSelectedEngineSize] = useState("");
  const [selectedMinYear, setSelectedMinYear] = useState("");
  const [selectedMaxYear, setSelectedMaxYear] = useState("");
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState(slug);

  const [banner, setBanner] = useState([]);

  const [error, setError] = useState(null);

  const [sortingOption, setSortingOption] = useState("desc_date"); // Initial sorting option
  const [grid, setGrid] = useState(true);
  const [isGrid, setIsGrid] = useState(false);

  const [product, setProduct] = useState([]);
  const [totalAds, setTotalAds] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page")) || 1;
  const [perPage, setPerPage] = useState(16);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState();

  const [wishlist, setWishlist] = useState([]);

  const [title, setTitle] = useState("");

  const [showYearPriceAccordions, setShowYearPriceAccordions] = useState(true);
  const [showCategoryAccordions, setShowCategoryAccordions] = useState(true);

  const [toastMessage, setToastMessage] = useState("");

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [searchCounty, setSearchCounty] = useState("");
  const [searchMake, setSearchMake] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [searchFuel, setSearchFuel] = useState("");
  const [searchBodyType, setSearchBodyType] = useState("");

  const [searchColour, setSearchColour] = useState("");
  const [searchEngineSize, setSearchEngineSize] = useState("");
  const [searchMinYear, setSearchMinYear] = useState("");
  const [searchMaxYear, setSearchMaxYear] = useState("");
  const [searchMinPrice, setSearchMinPrice] = useState("");
  const [searchMaxPrice, setSearchMaxPrice] = useState("");

  const [yearStatus, setYearStatus] = useState(true);
  const [priceStatus, setPriceStatus] = useState(true);

  const [saveSearch, setSaveSearch] = useState(false);
  const [load, setLoad] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  if (subcat != "cars" && subcat != "4x4-wheel-drive" && subcat != undefined) {
    document.title = `${selectedSubCategory} - thedealer.ie your one stop shop for buying or selling goods online in Ireland, Northern Ireland`;
  }
  else if (subcat === "cars" || subcat === "4x4-wheel-drive")
  {
    document.title = " - thedealer.ie your one stop shop for buying or selling goods online in Ireland, Northern Ireland"
  }
  else if (slug) {
    document.title = `${selectedCategory} For Sale in Ireland - TheDealer.ie`;
  }

  const toggleGrid = () => {
    setIsGrid(false);
    setGrid(true);
  };

  const toggleList = () => {
    setIsGrid(true);
    setGrid(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;

    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, currentPage]);

  const handleShowPerPage = (value) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if(subcat && slug)
    {
      navigate(
        `/${slug}/${subcat}?page=${page}`
      );
    }
    else {
      navigate(
        `/${slug}?page=${page}`
      );
    }
  };

  useEffect(() => {
    axios
      .get(
        `${DealerApi}/filter/all?fuel=${selectedFuel}&category_slug=${
          slug == "search" ? "" : slug
        }&min_price=${selectedMinPrice}&subcategory_slug=${
          subcat == undefined ? "" : subcat == "all" ? "" : subcat
        }&max_price=${selectedMaxPrice}&colour=${selectedColour}&county_slug=${selectedCounty}&ad_type=${selectedAccount}&body_type=${selectedBodyTypes}&model_slug=${selectedModel}&make_slug=${selectedMake}&engine_size=${selectedEngineSize}&max_year=${selectedMaxYear}&min_year=${selectedMinYear}`
      )
      .then((response) => {
        setCategory(response.data.body.result.categories);
        setSubcategory(response.data.body.result.subcategories);
        if (
          slug != "search" &&
          (subcat == "cars" || subcat == "4x4-wheel-drive")
        ) {
          const cat =
            response &&
            response.data.body.result.categories.find(
              (category) => category.slug === slug
            );
          setShowCategoryAccordions(false);
          setSelectedCategory(cat.category);
          setSearchCategory({
            category_name: cat.category,
            category_slug: cat.slug,
          });
          let capitalizedSubcat =
            subcat.charAt(0) + subcat.slice(1).replace(/-/g, " ");
          setSelectedSubCategory(capitalizedSubcat);
          setSelectedSubCategory(capitalizedSubcat);
          setSearchSubCategory({
            subcategory_name: capitalizedSubcat,
            subcategory_slug: subcat,
          });
          setTitle("");
          setShowYearPriceAccordions(true);
          setYearRange([
            response.data.body.result.year[0].min_year,
            response.data.body.result.year[0].max_year,
          ]);
          if (yearStatus) {
            setFirstYear([
              response.data.body.result.year[0].min_year,
              response.data.body.result.year[0].max_year,
            ]);
            setYearStatus(false);
          }
          setPriceRange([
            response.data.body.result.price[0].min_price,
            response.data.body.result.price[0].max_price,
          ]);
          if (priceStatus) {
            setFirstPrice([
              response.data.body.result.price[0].min_price,
              response.data.body.result.price[0].max_price,
            ]);
            setPriceStatus(false);
          }
        } else if (slug != "search" && subcat == undefined) {
          const cat =
            response &&
            response.data.body.result.categories.find(
              (category) => category.slug === slug
            );
          setShowCategoryAccordions(true);
          setSearchCategory({
            category_name: cat.category,
            category_slug: cat.slug,
          });
          if (cat) {
            if (selectedCounty) {
              setTitle(`${cat.category} in ${selectedCounty}`);
              setSelectedCategory(cat.category);
            } else {
              setTitle(cat.category);
              setSelectedCategory(cat.category);
            }
          }
        } else {
          if (slug != "search") {
            const cat =
              response &&
              response.data.body.result.categories.find(
                (category) => category.slug === slug
              );
            setSearchCategory({
              category_name: cat.category,
              category_slug: cat.slug,
            });
            setSelectedCategory(cat.category);
            const subCat =
              response &&
              response.data.body.result.subcategories.find(
                (category) => category.slug === subcat
              );

            setSearchSubCategory({
              subcategory_name: subCat.category,
              subcategory_slug: subCat.slug,
            });
            if (selectedCounty) {
              setTitle(`${subCat.category} in ${selectedCounty}`);
              setSelectedSubCategory(subCat.category);
            } else {
              setTitle(`${subCat.category}`);
              setSelectedSubCategory(subCat.category);
            }
          }
        }
        setAccount(response.data.body.result.account);
        setCounty(response.data.body.result.counties);
        setMakes(response.data.body.result.makes);
        setModel(response.data.body.result.model);
        setFuel(response.data.body.result.fuel);
        setBodyType(response.data.body.result.bodyTypes);
        setColour(response.data.body.result.colour);
        setEngineSize(response.data.body.result.engineSize);
      })
      .catch((err) => {});
  }, [
    slug,
    subcat,
    selectedCategory,
    selectedSubCategory,
    selectedAccount,
    selectedCounty,
    selectedMake,
    selectedModel,
    selectedFuel,
    selectedBodyTypes,
    selectedColour,
    selectedEngineSize,
    selectedMinYear,
    selectedMaxYear,
    selectedMinPrice,
    selectedMaxPrice,
  ]);

  useEffect(() => {
    setProduct([]);
    setLoad(true);
    axios
      .get(
        `${DealerApi}/listing?per_page=${perPage}&category_slug=${
          slug == "search" ? "" : slug
        }&subcategory_slug=${
          subcat == undefined ? "" : subcat == "all" ? "" : subcat
        }&ad_type=${selectedAccount}&county_slug=${selectedCounty}&page_no=${currentPage}&make_slug=${selectedMake}&model_slug=${selectedModel}&fuel=${selectedFuel}&body_type=${selectedBodyTypes}&engine_size=${selectedEngineSize}&colour=${selectedColour}&sorted_by=${
          subcat == "cars" ? "desc_score" : sortingOption
        }&min_year=${selectedMinYear}&max_year=${selectedMaxYear}&min_price=${selectedMinPrice}&max_price=${selectedMaxPrice}`
      )
      .then((response) => {
        setLoad(false);
        if (response.data.body.result.data.length === 0) {
          setTotalAds(0);
          setNotFound(true);
          setError("No Product Found.");
          setSelectedMinPrice("");
          setSelectedMaxPrice("");
          setTitle(response.data.body.result.sub_category);
          setSelectedSubCategory(response.data.body.result.sub_category);
        } else {
          setError(null);
          setNotFound(false);
          setProduct(response.data.body.result.data);
          setBanner(response.data.body.result.banner);
          setTotalAds(response.data.body.result.total);
          if (
            slug == "search" &&
            selectedCounty == "" &&
            selectedAccount == ""
          ) {
            setSaveSearch(false);
          } else {
            setSaveSearch(true);
          }
        }
        const calculatedTotalPages = Math.ceil(
          response.data.body.result.total / perPage
        );
        setTotalPages(calculatedTotalPages);
      })
      .catch((err) => {
        setLoad(false);
        if (err.response.data.body.success == 0) {
          setNotFound(true);
          setError("No Product Found.");
          setTotalAds(0);
        }
      });
  }, [
    slug,
    subcat,
    selectedCategory,
    selectedSubCategory,
    currentPage,
    perPage,
    selectedAccount,
    selectedCounty,
    selectedMake,
    selectedModel,
    selectedFuel,
    selectedBodyTypes,
    selectedColour,
    selectedEngineSize,
    selectedMinYear,
    selectedMaxYear,
    selectedMinPrice,
    selectedMaxPrice,
    sortingOption,
  ]);

  const handleCategory = (cat) => {
    navigate(`/search?cat=${cat.slug}&sub_cat=all&county=all&ad_type=all`);
    if (slug === selectedCategoryId) {
      setSubcategory("");
    }
    setSelectedCategoryId(cat.slug);
    setSelectedCategory(cat.category);
    setSelectedSubCategory("");
    setSearchCategory({ category_name: cat.category, category_slug: cat.slug });
    setSortingOption("desc_date");
  };

  const handleSubCategory = (sub) => {
    setSelectedSubCategory(sub.category);
    if (sub.slug == "cars" || sub.slug == "4x4-wheel-drive") {
      navigate(
        `/car_search?cat=${slug}&sub_cat=${sub.slug}&county=${
          selectedCounty ? selectedCounty : "all"
        }&ad_type=${selectedAccount ? selectedAccount : "all"}`
      );
    } else {
      navigate(
        `/search?cat=${slug}&sub_cat=${sub.slug}&county=${
          selectedCounty ? selectedCounty : "all"
        }&ad_type=${selectedAccount ? selectedAccount : "all"}`
      );
    }
    setSearchSubCategory({
      subcategory_name: sub.category,
      subcategory_slug: sub.slug,
    });
    setSortingOption("desc_date");
  };

  const handleAccountClick = (acc) => {
    if (subcat == "cars" || subcat == "4x4-wheel-drive") {
      navigate(
        `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
          subcat == undefined ? "all" : subcat
        }&county=${
          selectedCounty ? selectedCounty : "all"
        }&ad_type=${acc}&make=${selectedMake ? selectedMake : "all"}&model=${
          selectedModel ? selectedModel : "all"
        }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
          selectedMaxPrice ? selectedMaxPrice : "all"
        }&fuel=${selectedFuel ? selectedFuel : "all"}&body_type=${
          selectedBodyTypes ? selectedBodyTypes : "all"
        }&colour=${selectedColour ? selectedColour : "all"}&engine_size=${
          selectedEngineSize ? selectedEngineSize : "all"
        }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
          selectedMaxYear ? selectedMaxYear : "all"
        }`
      );
    } else {
      navigate(
        `/search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
          subcat == undefined ? "all" : subcat
        }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${acc}`
      );
    }
    setSelectedAccount(acc);
    setSearchAccount(acc);
    setSortingOption("desc_date");
  };

  const handleCountyClick = (county) => {
    if (subcat == "cars" || subcat == "4x4-wheel-drive") {
      navigate(
        `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
          subcat == undefined ? "all" : subcat
        }&county=${county.slug}&ad_type=${
          selectedAccount ? selectedAccount : "all"
        }&make=${selectedMake ? selectedMake : "all"}&model=${
          selectedModel ? selectedModel : "all"
        }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
          selectedMaxPrice ? selectedMaxPrice : "all"
        }&fuel=${selectedFuel ? selectedFuel : "all"}&body_type=${
          selectedBodyTypes ? selectedBodyTypes : "all"
        }&colour=${selectedColour ? selectedColour : "all"}&engine_size=${
          selectedEngineSize ? selectedEngineSize : "all"
        }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
          selectedMaxYear ? selectedMaxYear : "all"
        }`
      );
    } else {
      navigate(
        `/search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
          subcat == undefined ? "all" : subcat
        }&county=${county.slug}&ad_type=${
          selectedAccount ? selectedAccount : "all"
        }`
      );
    }
    setSelectedCounty(county.county);
    setSearchCounty({ county_name: county.county, county_slug: county.slug });
    setSortingOption("desc_date");
  };

  const handleMakeClick = (make) => {
    setSelectedMake(make.slug);
    setSearchMake({
      make_name: make.make,
      make_id: make.make_id,
      make_slug: make.slug,
    });
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${make.slug}&model=all&price_from=${
        selectedMinPrice ? selectedMinPrice : "all"
      }&price_to=${selectedMaxPrice ? selectedMaxPrice : "all"}&fuel=${
        selectedFuel ? selectedFuel : "all"
      }&body_type=${selectedBodyTypes ? selectedBodyTypes : "all"}&colour=${
        selectedColour ? selectedColour : "all"
      }&engine_size=${
        selectedEngineSize ? selectedEngineSize : "all"
      }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
        selectedMaxYear ? selectedMaxYear : "all"
      }`
    );
    setSortingOption("desc_date");
  };

  const handleModelClick = (model) => {
    setSelectedModel(model.slug);
    setSearchModel({
      model_name: model.model,
      model_id: model.model_id,
      model_slug: model.slug,
    });
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${selectedMake ? selectedMake : "all"}&model=${
        model.slug
      }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
        selectedMaxPrice ? selectedMaxPrice : "all"
      }&fuel=${selectedFuel ? selectedFuel : "all"}&body_type=${
        selectedBodyTypes ? selectedBodyTypes : "all"
      }&colour=${selectedColour ? selectedColour : "all"}&engine_size=${
        selectedEngineSize ? selectedEngineSize : "all"
      }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
        selectedMaxYear ? selectedMaxYear : "all"
      }`
    );
    setSortingOption("desc_date");
  };

  const handleFuelClick = (fuel) => {
    setSelectedFuel(fuel);
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${selectedMake ? selectedMake : "all"}&model=${
        selectedModel ? selectedModel : "all"
      }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
        selectedMaxPrice ? selectedMaxPrice : "all"
      }&fuel=${fuel}&body_type=${
        selectedBodyTypes ? selectedBodyTypes : "all"
      }&colour=${selectedColour ? selectedColour : "all"}&engine_size=${
        selectedEngineSize ? selectedEngineSize : "all"
      }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
        selectedMaxYear ? selectedMaxYear : "all"
      }`
    );
    setSearchFuel(fuel);
    setSortingOption("desc_date");
  };

  const handleBodyTypesClick = (bodytypes) => {
    setSelectedBodyTypes(bodytypes);
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${selectedMake ? selectedMake : "all"}&model=${
        selectedModel ? selectedModel : "all"
      }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
        selectedMaxPrice ? selectedMaxPrice : "all"
      }&fuel=${
        selectedFuel ? selectedFuel : "all"
      }&body_type=${bodytypes}&colour=${
        selectedColour ? selectedColour : "all"
      }&engine_size=${
        selectedEngineSize ? selectedEngineSize : "all"
      }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
        selectedMaxYear ? selectedMaxYear : "all"
      }`
    );
    setSearchBodyType(bodytypes);
    setSortingOption("desc_date");
  };

  const handleColorsClick = (color) => {
    setSelectedColour(color);
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${selectedMake ? selectedMake : "all"}&model=${
        selectedModel ? selectedModel : "all"
      }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
        selectedMaxPrice ? selectedMaxPrice : "all"
      }&fuel=${selectedFuel ? selectedFuel : "all"}&body_type=${
        selectedBodyTypes ? selectedBodyTypes : "all"
      }&colour=${color}&engine_size=${
        selectedEngineSize ? selectedEngineSize : "all"
      }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
        selectedMaxYear ? selectedMaxYear : "all"
      }`
    );
    setSearchColour(color);
    setSortingOption("desc_date");
  };

  const handleEngineSizeClick = (enginesize) => {
    setSelectedEngineSize(enginesize);
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${selectedMake ? selectedMake : "all"}&model=${
        selectedModel ? selectedModel : "all"
      }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
        selectedMaxPrice ? selectedMaxPrice : "all"
      }&fuel=${selectedFuel ? selectedFuel : "all"}&body_type=${
        selectedBodyTypes ? selectedBodyTypes : "all"
      }&colour=${
        selectedColour ? selectedColour : "all"
      }&engine_size=${enginesize}&min_year=${
        selectedMinYear ? selectedMinYear : "all"
      }&max_year=${selectedMaxYear ? selectedMaxYear : "all"}`
    );
    setSearchEngineSize(enginesize);
    setSortingOption("desc_date");
  };

  const handleYearRangeChange = (values) => {
    setYearStatus(false);
    setYearRange(values);
    setSelectedMinYear(values[0]);
    setSelectedMaxYear(values[1]);
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${selectedMake ? selectedMake : "all"}&model=${
        selectedModel ? selectedModel : "all"
      }&price_from=${selectedMinPrice ? selectedMinPrice : "all"}&price_to=${
        selectedMaxPrice ? selectedMaxPrice : "all"
      }&fuel=${selectedFuel ? selectedFuel : "all"}&body_type=${
        selectedBodyTypes ? selectedBodyTypes : "all"
      }&colour=${selectedColour ? selectedColour : "all"}&engine_size=${
        selectedEngineSize ? selectedEngineSize : "all"
      }&min_year=${values[0]}&max_year=${values[1]}`
    );
    setSearchMinYear(values[0]);
    setSearchMaxYear(values[1]);
    setSortingOption("desc_date");
  };

  const handlePriceRangeChange = (values) => {
    setPriceStatus(false);
    setPriceRange(values);
    setSelectedMinPrice(values[0]);
    setSelectedMaxPrice(values[1]);
    navigate(
      `/car_search?cat=${slug == "search" ? "all" : slug}&sub_cat=${
        subcat == undefined ? "all" : subcat
      }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${
        selectedAccount ? selectedAccount : "all"
      }&make=${selectedMake ? selectedMake : "all"}&model=${
        selectedModel ? selectedModel : "all"
      }&price_from=${values[0]}&price_to=${values[1]}&fuel=${
        selectedFuel ? selectedFuel : "all"
      }&body_type=${selectedBodyTypes ? selectedBodyTypes : "all"}&colour=${
        selectedColour ? selectedColour : "all"
      }&engine_size=${
        selectedEngineSize ? selectedEngineSize : "all"
      }&min_year=${selectedMinYear ? selectedMinYear : "all"}&max_year=${
        selectedMaxYear ? selectedMaxYear : "all"
      }`
    );
    setSearchMinPrice(values[0]);
    setSearchMaxPrice(values[1]);
    setSortingOption("desc_date");
  };

  const handleBannerClick = (link) => {
    window.open(link, "_blank");
  };

  const handleTooltip = (value) => {
    return `$${value.toFixed(2)}`;
  };

  const handleSortingChange = (label) => {
    setCurrentPage(1);
    switch (label) {
      case "relevance":
        setSortingOption("desc_score");
        break;
      case "mostRecent":
        setSortingOption("desc_date");
        break;
      case "lowestPrice":
        setSortingOption("asc_price");
        break;
      case "highestPrice":
        setSortingOption("desc_price");
        break;
      default:
        break;
    }
  };

  const handleBreadcrumbClick = async (
    category,
    subcategory,
    selectedMake,
    selectedModel,
    selectedFuel,
    selectedBodyType,
    selectedColors,
    selectedEngineSize
  ) => {
    setPerPage(16);
    setSortingOption("desc_date");
    if (selectedEngineSize) {
      setSelectedCounty("");
      setSearchCounty("");
    } else if (selectedColors) {
      setSelectedEngineSize("");
      setSelectedCounty("");
      setSearchCounty("");
      setSearchEngineSize("");
    } else if (selectedBodyType) {
      setSelectedEngineSize("");
      setSelectedColour("");
      setSelectedCounty("");
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
    } else if (selectedFuel) {
      setSelectedEngineSize("");
      setSelectedColour("");
      setSelectedBodyTypes("");
      setSelectedCounty("");
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
      setSearchBodyType("");
    } else if (selectedModel) {
      setSelectedEngineSize("");
      setSelectedColour("");
      setSelectedBodyTypes("");
      setSelectedFuel("");
      setSelectedCounty("");
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
      setSearchBodyType("");
      setSearchFuel("");
    } else if (selectedMake) {
      setSelectedModel("");
      setSelectedFuel("");
      setSelectedBodyTypes("");
      setSelectedEngineSize("");
      setSelectedColour("");
      setSelectedCounty("");
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
      setSearchBodyType("");
      setSearchFuel("");
      setSearchModel("");
    } else if (subcategory) {
      setSelectedColour("");
      setSelectedModel("");
      setSelectedMake("");
      setSelectedFuel("");
      setSelectedCounty("");
      setSelectedEngineSize("");
      setSelectedBodyTypes("");
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
      setSearchBodyType("");
      setSearchFuel("");
      setSearchModel("");
      setSearchMake("");
    } else {
      setSelectedMake("");
      setSelectedModel("");
      setSelectedFuel("");
      setSelectedBodyTypes("");
      setSelectedEngineSize("");
      setSelectedColour("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setSelectedAccount("");
      setSelectedCounty("");
      setShowYearPriceAccordions(false);
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
      setSearchBodyType("");
      setSearchFuel("");
      setSearchModel("");
      setSearchMake("");
      setSearchSubCategory("");
      setSelectedSubCategory("");
      setTitle(category.category);
      navigate(`/${slug}`);
    }
  };

  const handleRemove = (type) => {
    setCurrentPage(1);
    setPerPage(16);
    setSortingOption("desc_date");

    switch (type) {
      case "subcategory":
        setShowYearPriceAccordions(false);
        setSelectedCounty("");
        setSelectedAccount("");
        setSelectedMake("");
        setSelectedModel("");
        setSelectedFuel("");
        setSelectedBodyTypes("");
        setSelectedEngineSize("");
        setSelectedColour("");
        setSelectedMinPrice("");
        setSelectedMaxPrice("");
        setSelectedMinYear("");
        setSelectedMaxYear("");
        setSelectedSubCategory("");
        setSearchSubCategory("");
        navigate(`/${slug}`);
        break;
      case "account":
        setSelectedAccount("");
        setSearchAccount("");
        break;
      case "county":
        setSelectedCounty("");
        setSearchCounty("");
        break;
      case "make":
        setSelectedMake("");
        setSelectedModel("");
        setSearchMake("");
        setSearchModel("");
        break;
      case "model":
        setSelectedModel("");
        setSearchModel("");
        break;
      case "fuel":
        setSelectedFuel("");
        setSearchFuel("");
        break;
      case "bodytypes":
        setSelectedBodyTypes("");
        setSearchBodyType("");
        break;
      case "enginesize":
        setSelectedEngineSize("");
        setSearchEngineSize("");
        break;
      case "color":
        setSelectedColour("");
        setSearchColour("");
        break;
      case "minyear":
        setSelectedMinYear("");
        setSelectedMaxYear("");
        setSearchMinYear("");
        setSearchMaxYear("");
        setYearRange(firstYear);
        break;
      case "maxyear":
        setSelectedMinYear("");
        setSelectedMaxYear("");
        setSearchMaxYear("");
        setSearchMinYear("");
        setYearRange(firstYear);
        break;
      case "minprice":
        setSelectedMinPrice("");
        setSelectedMaxPrice("");
        setSearchMinPrice("");
        setSearchMaxPrice("");
        setPriceRange(firstPrice);
        break;
      case "maxprice":
        setSelectedMinPrice("");
        setSelectedMaxPrice("");
        setSearchMinPrice("");
        setSearchMaxPrice("");
        setPriceRange(firstPrice);
        break;
      default:
        break;
    }
  };

  const wishListFunction = async () => {
    if (tokenObject) {
      await axios
        .get(`${DealerApi}/wishlist/${tokenObject.id}`)
        .then((res) => {
          setWishlist(res.data.body.result.data);
        })
        .catch((err) => {});
    }
  };

  const updateWishlist = async () => {
    await wishListFunction();
    const updatedProducts = product.map((product) => {
      product.isInWishlist = isInWishlist(product.id);
      return product;
    });
    setProduct(updatedProducts);
  };

  const isInWishlist = (itemId) => {
    return wishlist && wishlist.some((item) => item.id === itemId);
  };

  useEffect(() => {
    updateWishlist();
  }, []);

//   useEffect(() => {
//     if (product.length > 0) {
//       updateWishlist();
//     }
// }, [product]);

  const handleSaveSearch = () => {
    if (!tokenObject || !tokenObject.token || !tokenObject.id) {
      navigate("/login");
    } else {
      axios
        .post(
          `${DealerApi}/search/store`,
          {
            search_key_word: "",
            user_id: tokenObject && tokenObject.id,
            filters: {
              category: searchCategory && searchCategory.category_name,
              category_slug: searchCategory && searchCategory.category_slug,
              subcategory:
                searchSubCategory && searchSubCategory.subcategory_name,
              subcategory_slug:
                searchSubCategory && searchSubCategory.subcategory_slug,
              county: searchCounty && searchCounty.county_name,
              county_slug: searchCounty && searchCounty.county_slug,
              body_type: searchBodyType,
              model_id: searchModel && searchModel.model_id,
              model: searchModel && searchModel.model_name,
              model_slug: searchModel && searchModel.model_slug,
              make_id: searchMake && searchMake.make_id,
              make: searchMake && searchMake.make_name,
              make_slug: searchMake && searchMake.make_slug,
              engine_size: searchEngineSize,
              min_year: searchMinYear,
              min_price: searchMinPrice,
              max_price: searchMaxPrice,
              ad_type: searchAccount,
              max_year: searchMaxYear,
              fuel: searchFuel,
              colour: searchColour,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${tokenObject && tokenObject.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setToastMessage("Successfully save in search page");
          setTimeout(() => {
            setToastMessage("");
          }, 5000);
        })
        .catch((e) => {});
    }
  };

  const handleAll = (all) => {
    if (all == "catAll") {
      navigate(
        `/search?cat=all&sub_cat=all&county=${
          selectedCounty ? selectedCounty : "all"
        }&ad_type=${selectedAccount ? selectedAccount : "all"}`
      );
    } else if (all == "accAll") {
      navigate(
        `/search?cat=${slug != "search" ? slug : "all"}&sub_cat=${
          subcat == undefined ? "all" : subcat
        }&county=${selectedCounty ? selectedCounty : "all"}&ad_type=${"all"}`
      );
    } else {
      navigate(
        `/search?cat=${slug != "search" ? slug : "all"}&sub_cat=${
          subcat == undefined ? "all" : subcat
        }&county=${"all"}&ad_type=${selectedAccount ? selectedAccount : "all"}`
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`${subcat?subcat=="all"?"":selectedSubCategory:selectedCategory} - Buy or sell anything online in Ireland, Northern, cars, furniture,household, agricultural, electrical, music, holidays, wedding, fashion, farming` }
        />
        <meta
          name="keywords"
          content={`${subcat?subcat=="all"?"":selectedSubCategory:selectedCategory} - thedealer.ie your one stop shop for buying or selling goods online in Ireland, Northern Ireland` }
        />
      </Helmet>
      <div className="category_page_main">
        <div className="container">
          <div className="row">
            <Breadcrumb>
              <Breadcrumb.Item href="/" className="home_breadcrumb">
                Home
              </Breadcrumb.Item>

              {selectedCategory && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick({ slug: category, category: title })
                  }
                >
                  {selectedCategory}
                </Breadcrumb.Item>
              )}

              {selectedSubCategory && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      { slug: subcategory, category: selectedSubCategory }
                    )
                  }
                >
                  {selectedSubCategory}
                </Breadcrumb.Item>
              )}

              {selectedMake && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      selectedMake
                    )
                  }
                >
                  {selectedMake}
                </Breadcrumb.Item>
              )}

              {selectedModel && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      selectedModel
                    )
                  }
                >
                  {selectedModel}
                </Breadcrumb.Item>
              )}

              {selectedFuel && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      selectedFuel
                    )
                  }
                >
                  {selectedFuel}
                </Breadcrumb.Item>
              )}

              {selectedBodyTypes && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      null,
                      selectedBodyTypes
                    )
                  }
                >
                  {selectedBodyTypes}
                </Breadcrumb.Item>
              )}

              {selectedColour && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      null,
                      null,
                      selectedColour
                    )
                  }
                >
                  {selectedColour}
                </Breadcrumb.Item>
              )}

              {selectedEngineSize && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      null,
                      null,
                      null,
                      selectedEngineSize
                    )
                  }
                >
                  {selectedEngineSize}
                </Breadcrumb.Item>
              )}

              {selectedCounty && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      { slug: subcategory, category: selectedSubCategory }
                    )
                  }
                >
                  {selectedCounty}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>

            <div className="col-lg-3 col-md-4 d-lg-block aside">
              {selectedSubCategory || selectedAccount || selectedCounty ? (
                <div className="selected_item_div">
                  <div className="selected_item_div">
                    <span className="selected_heading">Your Selection:</span>
                  </div>

                  {selectedSubCategory && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Subcategory:</span>{" "}
                        <span className="selected_div">
                          {selectedSubCategory}
                        </span>
                      </p>
                      <button
                        onClick={() => handleRemove("subcategory")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedAccount && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Account:</span>{" "}
                        <span className="selected_div">{selectedAccount}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("account")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedCounty && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">County:</span>{" "}
                        <span className="selected_div">{selectedCounty}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("county")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedMake && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Make:</span>{" "}
                        <span className="selected_div">{selectedMake}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("make")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedModel && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Model:</span>{" "}
                        <span className="selected_div">{selectedModel}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("model")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedFuel && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Fuel:</span>{" "}
                        <span className="selected_div">{selectedFuel}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("fuel")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedBodyTypes && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Body Type:</span>{" "}
                        <span className="selected_div">
                          {selectedBodyTypes}
                        </span>
                      </p>
                      <button
                        onClick={() => handleRemove("bodytypes")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedEngineSize && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Engine Size:</span>{" "}
                        <span className="selected_div">
                          {selectedEngineSize}
                        </span>
                      </p>
                      <button
                        onClick={() => handleRemove("enginesize")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedColour && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Colour:</span>{" "}
                        <span className="selected_div">{selectedColour}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("color")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedMinYear ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Min Year:</span>{" "}
                        {selectedMinYear}
                      </p>
                      <button
                        onClick={() => handleRemove("minyear")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMinYear === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Min Year:</span>{" "}
                          {selectedMinYear}
                        </p>
                        <button
                          onClick={() => handleRemove("minyear")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}

                  {selectedMaxYear ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Max Year:</span>{" "}
                        {selectedMaxYear}
                      </p>
                      <button
                        onClick={() => handleRemove("maxyear")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMaxYear === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Max Year:</span>{" "}
                          {selectedMaxYear}
                        </p>
                        <button
                          onClick={() => handleRemove("maxyear")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}

                  {selectedMinPrice ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Min Price:</span>{" "}
                        {selectedMinPrice}
                      </p>
                      <button
                        onClick={() => handleRemove("minprice")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMinPrice === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Min Price:</span>{" "}
                          {selectedMinPrice}
                        </p>
                        <button
                          onClick={() => handleRemove("minprice")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}

                  {selectedMaxPrice ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Max Price:</span>{" "}
                        {selectedMaxPrice}
                      </p>
                      <button
                        onClick={() => handleRemove("maxprice")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMaxPrice === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Max Price:</span>{" "}
                          {selectedMaxPrice}
                        </p>
                        <button
                          onClick={() => handleRemove("maxprice")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}
                </div>
              ) : null}

              <div
                className={`product_sidebar pt-20 ${
                  showOffcanvas ? "d-none d-md-block d-sm-block" : "" // Hide for smaller screens when Offcanvas is open
                }`}
              >
                <Accordion defaultActiveKey="0">
                  {showCategoryAccordions &&
                    category &&
                    category.length > 0 && (
                      <Accordion.Item eventKey="0">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Categories</h5>
                            </Accordion.Header>
                          </div>

                          <Accordion.Body className="accordion_body">
                            <div className="categories-container">
                              <p
                                className="category"
                                onClick={() => {
                                  handleAll("catAll");
                                }}
                              >
                                Categories (All)
                              </p>
                              {category.map((categories) => (
                                <div key={categories.id} className="category">
                                  <p onClick={() => handleCategory(categories)}>
                                    <IoIosArrowForward /> {categories.category}
                                  </p>
                                  {selectedCategoryId === categories.slug &&
                                    subcategory && (
                                      <ul className="subcategories">
                                        {subcategory.map((subcategories) => (
                                          <li
                                            key={subcategories.id}
                                            onClick={() =>
                                              handleSubCategory(subcategories)
                                            }
                                          >
                                            {subcategories.category} (
                                            {subcategories.listing_count})
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                </div>
                              ))}
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    )}

                  {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                    <>
                      <Accordion.Item eventKey="3" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Makes</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                setSelectedMake("");
                                setSelectedModel("");
                                setSelectedMinYear("");
                                setSelectedMaxYear("");
                                setSelectedMinPrice("");
                                setSelectedMaxPrice("");
                              }}
                            >
                              Makes (All)
                            </p>
                            {makes &&
                              makes.length > 0 &&
                              makes.map((make) => (
                                <>
                                  <p
                                    key={make.id}
                                    className="category"
                                    onClick={() => handleMakeClick(make)}
                                  >
                                    {make.make} ({make.listing_count})
                                  </p>
                                </>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                      {selectedMake && (
                        <div className="models-container">
                          <h5 className="title_model">Models</h5>
                          <p
                            className="category_model"
                            onClick={() => {
                              setSelectedModel("");
                              setSelectedMinYear("");
                              setSelectedMaxYear("");
                              setSelectedMinPrice("");
                              setSelectedMaxPrice("");
                            }}
                          >
                            Models (All)
                          </p>
                          {model.map((model) => (
                            <p
                              key={model.id}
                              className="category_model"
                              onClick={() => handleModelClick(model)}
                            >
                              {model.model} ({model.listing_count})
                            </p>
                          ))}
                        </div>
                      )}
                    </>
                  )}

                  {account && account.length > 0 && <Accordion.Item eventKey="1" className="accordion_space">
                    <div className="sidebar_categories mt-10">
                      <div className="sidebar_title">
                        <Accordion.Header>
                          <h5 className="category_title">Account</h5>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body className="accordion_body">
                        <p
                          className="category"
                          onClick={() => {
                            handleAll("accAll");
                          }}
                        >
                          Account (All)
                        </p>

                        {account &&
                          account.length > 0 &&
                          account.map((account) => (
                            <div key={account.id} className="category">
                              <p
                                onClick={() =>
                                  handleAccountClick(account.ad_type)
                                }
                                className="account_title"
                              >
                                {account.ad_type} ({account.listing_count})
                              </p>
                            </div>
                          ))}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>}

                  {county && county.length > 0 && <Accordion.Item eventKey="2" className="accordion_space">
                    <div className="sidebar_categories mt-10">
                      <div className="sidebar_title">
                        <Accordion.Header>
                          <h5 className="category_title">County</h5>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body className="accordion_body">
                        <p
                          className="category"
                          onClick={() => {
                            handleAll("countyAll");
                          }}
                        >
                          Counties (All)
                        </p>
                        {county &&
                          county.length > 0 &&
                          county.map((county) => (
                            <p
                              key={county.id}
                              className="category"
                              onClick={() => handleCountyClick(county)}
                            >
                              {county.county} ({county.listing_count})
                            </p>
                          ))}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>}

                  {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                    <>
                      <Accordion.Item eventKey="4" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Fuels</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="account_title"
                              onClick={() => {
                                setSelectedFuel("");
                                setSelectedMinYear("");
                                setSelectedMaxYear("");
                                setSelectedMinPrice("");
                                setSelectedMaxPrice("");
                              }}
                            >
                              Fuel Type (All)
                            </p>
                            {fuel &&
                              fuel.length > 0 &&
                              fuel.map((fuel) => (
                                <p
                                  key={fuel.id}
                                  className="account_title"
                                  onClick={() => handleFuelClick(fuel.fuel)}
                                >
                                  {fuel.fuel} ({fuel.listing_count})
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Body Type</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="account_title"
                              onClick={() => {
                                setSelectedBodyTypes("");
                                setSelectedMinYear("");
                                setSelectedMaxYear("");
                                setSelectedMinPrice("");
                                setSelectedMaxPrice("");
                              }}
                            >
                              Body Type (All)
                            </p>
                            {bodyType &&
                              bodyType.length > 0 &&
                              bodyType.map((bodytype) => (
                                <p
                                  key={bodytype.id}
                                  className="account_title"
                                  onClick={() =>
                                    handleBodyTypesClick(bodytype.body_type)
                                  }
                                >
                                  {bodytype.body_type} ({bodytype.listing_count}
                                  )
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="6" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Colour</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                setSelectedColour("");
                                setSelectedMinYear("");
                                setSelectedMaxYear("");
                                setSelectedMinPrice("");
                                setSelectedMaxPrice("");
                              }}
                            >
                              All
                            </p>
                            <div className="category color-grid">
                              {colour &&
                                colour.length > 0 &&
                                colour.map((item) => {
                                  const color = item.colour;
                                  if (color) {
                                    return (
                                      <div
                                        key={item.id || item.colour}
                                        className="color_spilt_div"
                                      >
                                        <div className="color">
                                          <div
                                            className="color_div"
                                            style={{
                                              backgroundColor:
                                                color === "burgundy"
                                                  ? "#800020"
                                                  : color === "bronze"
                                                  ? "#CD7F32"
                                                  : color,
                                            }}
                                            onClick={() =>
                                              handleColorsClick(color)
                                            }
                                          ></div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="7" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Engine Size</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                setSelectedEngineSize("");
                                setSelectedMinYear("");
                                setSelectedMaxYear("");
                                setSelectedMinPrice("");
                                setSelectedMaxPrice("");
                              }}
                            >
                              Engine Size (All)
                            </p>
                            {engineSize &&
                              engineSize.length > 0 &&
                              engineSize.map((enginesize) => (
                                <p
                                  key={enginesize.id}
                                  className="category"
                                  onClick={() =>
                                    handleEngineSizeClick(
                                      enginesize.engine_size
                                    )
                                  }
                                >
                                  {enginesize.engine_size} (
                                  {enginesize.listing_count})
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                          <Accordion.Item
                            eventKey="8"
                            className="accordion_space"
                          >
                            <div className="sidebar_categories mt-10">
                              <div className="sidebar_title">
                                <Accordion.Header>
                                  <h5 className="category_title">Year</h5>
                                </Accordion.Header>
                              </div>
                              <Accordion.Body className="accordion_body">
                                <div>
                                  <label>Filter By: Year</label>

                                  <Slider
                                    key={yearRange.join("-")}
                                    range
                                    min={firstYear[0]}
                                    max={firstYear[1]}
                                    step={1}
                                    defaultValue={yearRange}
                                    onChangeComplete={handleYearRangeChange}
                                    allowCross={false}
                                  />

                                  <div className="slider_label">
                                    <span>
                                      {yearRange[0] === null
                                        ? "0"
                                        : yearRange[0]}
                                    </span>
                                    <span>
                                      {yearRange[1] === null
                                        ? "0"
                                        : yearRange[1]}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </div>
                          </Accordion.Item>
                        )}

                      {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                          <Accordion.Item
                            eventKey="9"
                            className="accordion_space"
                          >
                            <div className="sidebar_categories mt-10">
                              <div className="sidebar_title">
                                <Accordion.Header>
                                  <h5 className="category_title">Price</h5>
                                </Accordion.Header>
                              </div>
                              <Accordion.Body className="accordion_body">
                                <div>
                                  <label>Filter By: Price</label>
                                  <Slider
                                    key={priceRange.join("-")}
                                    range
                                    min={firstPrice[0]}
                                    max={firstPrice[1]}
                                    step={1}
                                    defaultValue={priceRange}
                                    onChangeComplete={handlePriceRangeChange}
                                    tipFormatter={handleTooltip}
                                    allowCross={false}
                                  />
                                  <div className="slider_label">
                                    <span>
                                      {priceRange[0] === null
                                        ? "0"
                                        : priceRange[0]}
                                    </span>
                                    <span>
                                      {priceRange[1] === null
                                        ? "0"
                                        : priceRange[1]}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </div>
                          </Accordion.Item>
                        )}
                    </>
                  )}
                </Accordion>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              {banner && banner.length > 0 && (
                <div className="carousel_card1 text-center">
                  <div className="carousel-container">
                    <Carousel
                      controls={false}
                      indicators={false}
                      interval={5000}
                    >
                      {banner.map((item, index) => (
                        <Carousel.Item
                          key={index}
                          onClick={() => handleBannerClick(item.banner_link)}
                        >
                          <img
                            className="feature_img"
                            src={item.url}
                            alt={item.banner_name}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
              )}

              <Toast
                show={toastMessage !== ""}
                onClose={() => setToastMessage("")}
                className="fade bg-success toast_message_div"
              >
                <Toast.Body className="toast_message">
                  {toastMessage}
                </Toast.Body>
              </Toast>

              <div className="ads_total_div">
                <h3 className="product_title">{title}</h3>
                <h3 className="product_ads">{totalAds} Ads</h3>
              </div>

              <Offcanvas
                show={showOffcanvas}
                onHide={handleCloseOffcanvas}
                className="d-block d-lg-none"
                style={{ overflowY: "scroll" }}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  {selectedSubCategory || selectedAccount || selectedCounty ? (
                    <div className="selected_item_div">
                      <div className="selected_item_div">
                        <span className="selected_heading">
                          Your Selection:
                        </span>
                      </div>

                      {selectedSubCategory && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Subcategory:</span>{" "}
                            <span className="selected_div">
                              {selectedSubCategory}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("subcategory")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedAccount && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Account:</span>{" "}
                            <span className="selected_div">
                              {selectedAccount}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("account")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedCounty && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">County:</span>{" "}
                            <span className="selected_div">
                              {selectedCounty}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("county")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedMake && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Make:</span>{" "}
                            <span className="selected_div">{selectedMake}</span>
                          </p>
                          <button
                            onClick={() => handleRemove("make")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedModel && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Model:</span>{" "}
                            <span className="selected_div">
                              {selectedModel}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("model")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedFuel && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Fuel:</span>{" "}
                            <span className="selected_div">{selectedFuel}</span>
                          </p>
                          <button
                            onClick={() => handleRemove("fuel")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedBodyTypes && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Body Type:</span>{" "}
                            <span className="selected_div">
                              {selectedBodyTypes}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("bodytypes")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedEngineSize && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Engine Size:</span>{" "}
                            <span className="selected_div">
                              {selectedEngineSize}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("enginesize")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedColour && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Colour:</span>{" "}
                            <span className="selected_div">
                              {selectedColour}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("color")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedMinYear ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Min Year:</span>{" "}
                            {selectedMinYear}
                          </p>
                          <button
                            onClick={() => handleRemove("minyear")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMinYear === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Min Year:</span>{" "}
                              {selectedMinYear}
                            </p>
                            <button
                              onClick={() => handleRemove("minyear")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}

                      {selectedMaxYear ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Max Year:</span>{" "}
                            {selectedMaxYear}
                          </p>
                          <button
                            onClick={() => handleRemove("maxyear")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMaxYear === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Max Year:</span>{" "}
                              {selectedMaxYear}
                            </p>
                            <button
                              onClick={() => handleRemove("maxyear")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}

                      {selectedMinPrice ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Min Price:</span>{" "}
                            {selectedMinPrice}
                          </p>
                          <button
                            onClick={() => handleRemove("minprice")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMinPrice === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Min Price:</span>{" "}
                              {selectedMinPrice}
                            </p>
                            <button
                              onClick={() => handleRemove("minprice")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}

                      {selectedMaxPrice ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Max Price:</span>{" "}
                            {selectedMaxPrice}
                          </p>
                          <button
                            onClick={() => handleRemove("maxprice")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMaxPrice === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Max Price:</span>{" "}
                              {selectedMaxPrice}
                            </p>
                            <button
                              onClick={() => handleRemove("maxprice")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}

                  <Accordion defaultActiveKey="0">
                    {showCategoryAccordions &&
                      category &&
                      category.length > 0 && (
                        <Accordion.Item eventKey="0">
                          <div className="sidebar_categories mt-10">
                            <div className="sidebar_title">
                              <Accordion.Header>
                                <h5 className="category_title">Categories</h5>
                              </Accordion.Header>
                            </div>

                            <Accordion.Body className="accordion_body">
                              <div className="categories-container">
                                <p
                                  className="category"
                                  onClick={() => {
                                    handleAll("catAll");
                                  }}
                                >
                                  Categories (All)
                                </p>
                                {category.map((categories) => (
                                  <div key={categories.id} className="category">
                                    <p
                                      onClick={() => handleCategory(categories)}
                                    >
                                      <IoIosArrowForward />{" "}
                                      {categories.category}
                                    </p>
                                    {selectedCategoryId === categories.slug &&
                                      subcategory && (
                                        <ul className="subcategories">
                                          {subcategory.map((subcategories) => (
                                            <li
                                              key={subcategories.id}
                                              onClick={() =>
                                                handleSubCategory(subcategories)
                                              }
                                            >
                                              {subcategories.category} (
                                              {subcategories.listing_count})
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                  </div>
                                ))}
                              </div>
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>
                      )}

                    {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                      <>
                        <Accordion.Item
                          eventKey="3"
                          className="accordion_space"
                        >
                          <div className="sidebar_categories mt-10">
                            <div className="sidebar_title">
                              <Accordion.Header>
                                <h5 className="category_title">Makes</h5>
                              </Accordion.Header>
                            </div>
                            <Accordion.Body className="accordion_body">
                              <p
                                className="category"
                                onClick={() => {
                                  setSelectedMake("");
                                  setSelectedModel("");
                                  setSelectedMinYear("");
                                  setSelectedMaxYear("");
                                  setSelectedMinPrice("");
                                  setSelectedMaxPrice("");
                                }}
                              >
                                Makes (All)
                              </p>
                              {makes &&
                                makes.length > 0 &&
                                makes.map((make) => (
                                  <>
                                    <p
                                      key={make.id}
                                      className="category"
                                      onClick={() => handleMakeClick(make)}
                                    >
                                      {make.make} ({make.listing_count})
                                    </p>
                                  </>
                                ))}
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>
                        {selectedMake && (
                          <div className="models-container">
                            <h5 className="title_model">Models</h5>
                            <p
                              className="category_model"
                              onClick={() => {
                                setSelectedModel("");
                                setSelectedMinYear("");
                                setSelectedMaxYear("");
                                setSelectedMinPrice("");
                                setSelectedMaxPrice("");
                              }}
                            >
                              Models (All)
                            </p>
                            {model.map((model) => (
                              <p
                                key={model.id}
                                className="category_model"
                                onClick={() => handleModelClick(model)}
                              >
                                {model.model} ({model.listing_count})
                              </p>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                    {account && account.length > 0 &&<Accordion.Item eventKey="1" className="accordion_space">
                      <div className="sidebar_categories mt-10">
                        <div className="sidebar_title">
                          <Accordion.Header>
                            <h5 className="category_title">Account</h5>
                          </Accordion.Header>
                        </div>
                        <Accordion.Body className="accordion_body">
                          <p
                            className="category"
                            onClick={() => {
                              handleAll("accAll");
                            }}
                          >
                            Account (All)
                          </p>

                          {account &&
                            account.length > 0 &&
                            account.map((account) => (
                              <div key={account.id} className="category">
                                <p
                                  onClick={() =>
                                    handleAccountClick(account.ad_type)
                                  }
                                  className="account_title"
                                >
                                  {account.ad_type} ({account.listing_count})
                                </p>
                              </div>
                            ))}
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>}

                    {county && county.length > 0 &&<Accordion.Item eventKey="2" className="accordion_space">
                      <div className="sidebar_categories mt-10">
                        <div className="sidebar_title">
                          <Accordion.Header>
                            <h5 className="category_title">County</h5>
                          </Accordion.Header>
                        </div>
                        <Accordion.Body className="accordion_body">
                          <p
                            className="category"
                            onClick={() => {
                              handleAll("countyAll");
                            }}
                          >
                            Counties (All)
                          </p>
                          {county &&
                            county.length > 0 &&
                            county.map((county) => (
                              <p
                                key={county.id}
                                className="category"
                                onClick={() => handleCountyClick(county)}
                              >
                                {county.county} ({county.listing_count})
                              </p>
                            ))}
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>}

                    {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                      <>
                        <Accordion.Item
                          eventKey="4"
                          className="accordion_space"
                        >
                          <div className="sidebar_categories mt-10">
                            <div className="sidebar_title">
                              <Accordion.Header>
                                <h5 className="category_title">Fuels</h5>
                              </Accordion.Header>
                            </div>
                            <Accordion.Body className="accordion_body">
                              <p
                                className="account_title"
                                onClick={() => {
                                  setSelectedFuel("");
                                  setSelectedMinYear("");
                                  setSelectedMaxYear("");
                                  setSelectedMinPrice("");
                                  setSelectedMaxPrice("");
                                }}
                              >
                                Fuel Type (All)
                              </p>
                              {fuel &&
                                fuel.length > 0 &&
                                fuel.map((fuel) => (
                                  <p
                                    key={fuel.id}
                                    className="account_title"
                                    onClick={() => handleFuelClick(fuel.fuel)}
                                  >
                                    {fuel.fuel} ({fuel.listing_count})
                                  </p>
                                ))}
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="5"
                          className="accordion_space"
                        >
                          <div className="sidebar_categories mt-10">
                            <div className="sidebar_title">
                              <Accordion.Header>
                                <h5 className="category_title">Body Type</h5>
                              </Accordion.Header>
                            </div>
                            <Accordion.Body className="accordion_body">
                              <p
                                className="account_title"
                                onClick={() => {
                                  setSelectedBodyTypes("");
                                  setSelectedMinYear("");
                                  setSelectedMaxYear("");
                                  setSelectedMinPrice("");
                                  setSelectedMaxPrice("");
                                }}
                              >
                                Body Type (All)
                              </p>
                              {bodyType &&
                                bodyType.length > 0 &&
                                bodyType.map((bodytype) => (
                                  <p
                                    key={bodytype.id}
                                    className="account_title"
                                    onClick={() =>
                                      handleBodyTypesClick(bodytype.body_type)
                                    }
                                  >
                                    {bodytype.body_type} (
                                    {bodytype.listing_count})
                                  </p>
                                ))}
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="6"
                          className="accordion_space"
                        >
                          <div className="sidebar_categories mt-10">
                            <div className="sidebar_title">
                              <Accordion.Header>
                                <h5 className="category_title">Colour</h5>
                              </Accordion.Header>
                            </div>
                            <Accordion.Body className="accordion_body">
                              <p
                                className="category"
                                onClick={() => {
                                  setSelectedColour("");
                                  setSelectedMinYear("");
                                  setSelectedMaxYear("");
                                  setSelectedMinPrice("");
                                  setSelectedMaxPrice("");
                                }}
                              >
                                All
                              </p>
                              <div className="category color-grid">
                                {colour &&
                                  colour.length > 0 &&
                                  colour.map((item) => {
                                    const color = item.colour;
                                    if (color) {
                                      return (
                                        <div
                                          key={item.id || item.colour}
                                          className="color_spilt_div"
                                        >
                                          <div className="color">
                                            <div
                                              className="color_div"
                                              style={{
                                                backgroundColor:
                                                  color === "burgundy"
                                                    ? "#800020"
                                                    : color === "bronze"
                                                    ? "#CD7F32"
                                                    : color,
                                              }}
                                              onClick={() =>
                                                handleColorsClick(color)
                                              }
                                            ></div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="7"
                          className="accordion_space"
                        >
                          <div className="sidebar_categories mt-10">
                            <div className="sidebar_title">
                              <Accordion.Header>
                                <h5 className="category_title">Engine Size</h5>
                              </Accordion.Header>
                            </div>
                            <Accordion.Body className="accordion_body">
                              <p
                                className="category"
                                onClick={() => {
                                  setSelectedEngineSize("");
                                  setSelectedMinYear("");
                                  setSelectedMaxYear("");
                                  setSelectedMinPrice("");
                                  setSelectedMaxPrice("");
                                }}
                              >
                                Engine Size (All)
                              </p>
                              {engineSize &&
                                engineSize.length > 0 &&
                                engineSize.map((enginesize) => (
                                  <p
                                    key={enginesize.id}
                                    className="category"
                                    onClick={() =>
                                      handleEngineSizeClick(
                                        enginesize.engine_size
                                      )
                                    }
                                  >
                                    {enginesize.engine_size} (
                                    {enginesize.listing_count})
                                  </p>
                                ))}
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>

                        {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                            <Accordion.Item
                              eventKey="8"
                              className="accordion_space"
                            >
                              <div className="sidebar_categories mt-10">
                                <div className="sidebar_title">
                                  <Accordion.Header>
                                    <h5 className="category_title">Year</h5>
                                  </Accordion.Header>
                                </div>
                                <Accordion.Body className="accordion_body">
                                  <div>
                                    <label>Filter By: Year</label>

                                    <Slider
                                      key={yearRange.join("-")}
                                      range
                                      min={firstYear[0]}
                                      max={firstYear[1]}
                                      step={1}
                                      defaultValue={yearRange}
                                      onChangeComplete={handleYearRangeChange}
                                      allowCross={false}
                                    />

                                    <div className="slider_label">
                                      <span>
                                        {yearRange[0] === null
                                          ? "0"
                                          : yearRange[0]}
                                      </span>
                                      <span>
                                        {yearRange[1] === null
                                          ? "0"
                                          : yearRange[1]}
                                      </span>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </div>
                            </Accordion.Item>
                          )}

                        {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                            <Accordion.Item
                              eventKey="9"
                              className="accordion_space"
                            >
                              <div className="sidebar_categories mt-10">
                                <div className="sidebar_title">
                                  <Accordion.Header>
                                    <h5 className="category_title">Price</h5>
                                  </Accordion.Header>
                                </div>
                                <Accordion.Body className="accordion_body">
                                  <div>
                                    <label>Filter By: Price</label>
                                    <Slider
                                      key={priceRange.join("-")}
                                      range
                                      min={firstPrice[0]}
                                      max={firstPrice[1]}
                                      step={1}
                                      defaultValue={priceRange}
                                      onChangeComplete={handlePriceRangeChange}
                                      tipFormatter={handleTooltip}
                                      allowCross={false}
                                    />
                                    <div className="slider_label">
                                      <span>
                                        {priceRange[0] === null
                                          ? "0"
                                          : priceRange[0]}
                                      </span>
                                      <span>
                                        {priceRange[1] === null
                                          ? "0"
                                          : priceRange[1]}
                                      </span>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </div>
                            </Accordion.Item>
                          )}
                      </>
                    )}
                  </Accordion>

                  <button
                    onClick={() => {
                      handleCloseOffcanvas();
                    }}
                    className="login_btn mt-4"
                  >
                    Apply Filter
                  </button>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="product_dropdown_main">
                <div className="product_dropdown">
                  {/* Offcanvas Trigger Button */}
                  <button
                    onClick={handleShowOffcanvas}
                    className="d-block d-lg-none filter_btn"
                  >
                    Filter
                  </button>

                  {subcat != "cars" && subcat != "4x4-wheel-drive" && (
                    <>
                      <Dropdown className="dropdown_page">
                        <Dropdown.Toggle
                          className="dropdown_page_main"
                          id="dropdown-basic"
                        >
                          Show {perPage}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleShowPerPage(16)}>
                            Show 16
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleShowPerPage(32)}>
                            Show 32
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleShowPerPage(60)}>
                            Show 60
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <Dropdown className="dropdown_page">
                        <Dropdown.Toggle
                          className="dropdown_page_main"
                          id="dropdown-basic"
                        >
                          {sortingOption === "desc_score"
                            ? "Relavance"
                            : sortingOption === "desc_date"
                            ? "Most Recent"
                            : sortingOption === "asc_price"
                            ? "Lowest Price"
                            : sortingOption === "desc_price"
                            ? "Highest Price"
                            : ""}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleSortingChange("relevance")}
                          >
                            Relavance
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleSortingChange("mostRecent")}
                          >
                            Most Recent
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleSortingChange("lowestPrice")}
                          >
                            Lowest Price
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleSortingChange("highestPrice")}
                          >
                            Highest Price
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}

                  {saveSearch && (
                    <button
                      onClick={handleSaveSearch}
                      className="save_search_btn"
                    >
                      Save Search Results
                    </button>
                  )}
                </div>

                <div className="product_dropdown product_dropdown_grid">
                  <LuGrid
                    className={`grid_icon ${isGrid ? "active" : ""}`}
                    onClick={toggleGrid}
                  />

                  <LiaThListSolid
                    className={`list_icon ${!isGrid ? "active" : ""}`}
                    onClick={toggleList}
                  />
                </div>
              </div>

              {notFound && (
                  <div class="preview clicks">
                    <div class="image">
                    <img src={megaphone} alt="megaphone"/>
                    </div>
                    <h1>Sorry, no results found.</h1>
                    <p>We have nothing that matches your search. 
                      Browse all of our ads using the <a href={process.env.REACT_APP_API_REPORT} className="main_category">main categories</a>.</p>
                  </div>
              )}

              <div className="load_img_div">
                {(load && !error) && (
                 <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
                )}
              </div>

              {!error && (
                <div>
                  <div className={`${grid ? "grid" : "list"}`}>
                    {grid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryProductCard
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                    })}
                    {isGrid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryListing
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                      })}
                  </div>

                  {load == false && product && totalPages && totalPages > 1 && (
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CategoryPage;