import React from "react";
import "./BusinessDirectoryInfo.css";
import macbusinessdirectory from "../Images/mac-businessdirectory.jpg";
import Footer from "../Component/Footer";
import list from "../Images/list_style.png";
import { useNavigate } from "react-router";
import { Breadcrumb } from "react-bootstrap";
import Heder from "../Component/Heder";

const BusinessDirectoryInfo = () => {
  const navigate = useNavigate();

  const handleGetInTouch = () => {
    navigate("/contact");
  };

  return (
    <>
    <Heder name="business directory sign up"/>
      <div className="bus_main_div">
        <div className="container">
          <div className="business_info_div">
            <Breadcrumb>
              <Breadcrumb.Item href="/" className="home_breadcrumb">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                href="/business_directory/search/s/all/location"
                className="home_breadcrumb"
              >
                Business Directory
              </Breadcrumb.Item>
              <Breadcrumb.Item className="home_breadcrumb active">
                Business Directory Info
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-12">
                <h1 className="bus_dir_heading">Become a “Registered Business User"</h1>
                <p>
                  Have Multiple Classified Ads PLUS a dedicated business profile
                  page indexed on Google and other search engines, with your
                  branding, business details, description, map and link to your
                  socials all listed on one page. Packages from as little as
                  €180 plus VAT annual fee (for a limited time offer).
                </p>
                <div className="bus_list_div">
                  <ul className="bus_list_small">
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Profile Page on thedealer.ie
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Business description
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Contact information
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Get directions map
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Responsive design
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Social media links
                      </div>
                    </li>
                  </ul>
                  <ul className="bus_list_large">
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Classified Ads on thedealer.ie
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>Be
                        found by location, name, product or service
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Your company logo / branding.
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Link to your website on each ad you place
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Feature on a site with thousands of visitors per month.
                      </div>
                    </li>
                    <li>
                      <div className="list_div">
                        <img src={list} alt="list" className="list_img"></img>
                        Affordable &amp; effective online presence
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12">
                <img
                  src={macbusinessdirectory}
                  alt="macbusinessdirectory"
                  className="bus_image"
                />
              </div>
              <div className="col-12 bus_dir_info_div">
                <button
                  className="bus_get_in_touch_btn"
                  onClick={handleGetInTouch}
                >
                  Get in Touch
                </button>
                <p className="bus_dir_info">
                  Want to learn more about what we offer and get your business
                  listed on thedealer.ie? Phone:
                  <a href="tel:0035374 9126410">0035374 9126410</a>. Text
                  WhatsApp: 0831602031 / 00353831602031. Email:
                  <a href="mailto:sales@thedealer.ie">sales@thedealer.ie</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BusinessDirectoryInfo;