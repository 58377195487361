import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

const ScrollToTopOnPageChange = () => {
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to the top when the page changes
  // }, [pathname]); // Re-run effect when the pathname changes

  // Scroll to the top when the component mounts (page refresh)
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []); // Run only once after the component mounts

  return null; // This component doesn't render anything
};

export default ScrollToTopOnPageChange;