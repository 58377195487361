import React, { useState, useEffect, useRef } from "react";
import {
  LoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import { DealerApi } from "../Component/Api";
import "./BusinessDirectory.css";
import Pagination from "../Component/Pagination";
import axios from "axios";
import { IoLocationSharp } from "react-icons/io5";
import Footer from "../Component/Footer";
import marker from "../Images/dealer-marker.png";
import marker_black from "../Images/dealer-marker-black.png";
import icon_marker_small from "../Images/marker-small.png";
import { FaGreaterThan } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import Heder from "../Component/Heder";

const BusinessDirectory = () => {
  const url = useParams();
  const countyName = url.all;
  const [companyName, setCompanyName] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValueSmall, setSearchValueSmall] = useState(countyName && countyName !== "all" ? countyName : "");
  const [userEnteredValue, setUserEnteredValue] = useState(""); 
  const [recommendationsSmall, setRecommendationsSmall] = useState([]);
  const [bigStatus, setBigStatus] = useState(false);
  const [smallStatus, setSmallStatus] = useState(false);
  const [noDataBig, setNoDataBig] = useState(false);
  const [name, setName] = useState("");
  const [previousHoveredMarkerId, setPreviousHoveredMarkerId] = useState(null);
  const [center, setCenter] = useState({});
  const [defaultZoom, setDefaultZoom] = useState(7.3);
  const [loading, setLoading] = useState(false);
  const [selectedValueBig, setSelectedValueBig] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [clientId, setClientId] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();
  const [smallInput, setSmallInput] = useState(true);
  const [recommendationClicked, setRecommendationClicked] = useState(false);
  const [recommendSmall, setRecommendSmall] = useState(false);
  const [recommendBig, setRecommendBig] = useState(false);
  const paginationRef = useRef(null);

  useEffect(() => {
    if (countyName && countyName.trim() !== "") {
      document.title = `all throughout ${countyName}`;
    } else {
      document.title = "all throughout all";
    }
  }, [countyName]);

  useEffect(() => {
    setSelectedItemIndex(-1);
  }, [searchValue]);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [searchValueSmall]);

  useEffect(() => {
  axios.get(`${DealerApi}/keys`)
  .then((response) => {
    setClientId(response.data.body.result.google_api_key)
    setCenter({lat:response.data.body.result.google_api_key.default_lat, lang:response.data.body.result.google_api_key.default_long})
  })
  .catch((err)=> {
  })
  },[])

  useEffect(() => {
    if (countyName && countyName !== "all") {
      setSearchValueSmall(countyName);
      fetchData(countyName); // Fetch data with URL param if needed
    }
  }, [countyName]);

  const fetchData = async () => {
    const convertedText = searchValue && searchValue.replace("&", "%26");
    const convertedTextSmall = searchValueSmall && searchValueSmall.replace("&", "%26");
    if (
      (searchValue !== "" && searchValueSmall !== "") ||
      (searchValue !== "" && searchValueSmall == "")
    ) {
      setLoading(true);
      await axios
        .get(
          `${DealerApi}/user/business/directory?directory_name=${convertedText}&directory_location=${convertedTextSmall}&per_page=${perPage}&page_no=${currentPage}`
        )
        .then((res) => {
          let id =
            res.data.body.result.data.directoryData &&
            res.data.body.result.data.directoryData[0].id;
          const company_name =
            res.data.body.result.data.directoryData &&
            res.data.body.result.data.directoryData[0].company_name;
          setLoading(true);
          if(res.data.body.result.data.directoryData.length==1){
            setTimeout(() => {
              window.location.href=`/business_directory/details/${id}/${convertToUrl(company_name)}`
            }, 1000);
          }else{
          setCompanyName(res.data.body.result.data.directoryData);
          setName(res.data.body.result.data.status);
          setCenter({
            lat: +res.data.body.result.data.map_centere.latitude,
            lng: +res.data.body.result.data.map_centere.longitude,
          });
          const calculatedTotalPages = Math.ceil(
            res.data.body.result.total / perPage
          );
          setTotal(calculatedTotalPages);
          setLoading(false);
          setDefaultZoom(9);
          }
        })
        .catch((err) => {
          setLoading(true);
          if (err.response.data.body.success == 0) {
            setTimeout(() => {
              window.location.href=`/ad_inactive`
            }, 1000);
          }
        });
    } else if (searchValue == "" && searchValueSmall !== "") {
      setLoading(true);
      await axios
        .get(
          `${DealerApi}/user/business/directory?directory_name=${convertedText}&directory_location=${convertedTextSmall}&per_page=${perPage}&page_no=${currentPage}`
        )
        .then((res) => {
          navigate(`/business_directory/search/s/all/location/${res.data.body.result.data.slug}`);
          if(smallInput){
            setSearchValueSmall(res.data.body.result.data.location);
          }
          setCompanyName(res.data.body.result.data.directoryData);
          setName(res.data.body.result.data.status);
          setCenter({
            lat: +res.data.body.result.data.map_centere.latitude,
            lng: +res.data.body.result.data.map_centere.longitude,
          });
          const calculatedTotalPages = Math.ceil(
            res.data.body.result.total / perPage
          );
          setTotal(calculatedTotalPages);
          setLoading(false);
          setDefaultZoom(9);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      await axios
        .get(
          `${DealerApi}/user/business/directory?directory_name=${convertedText}&directory_location=${convertedTextSmall}&per_page=${perPage}&page_no=${currentPage}`
        )
        .then((res) => {
          setCompanyName(res.data.body.result.data.directoryData);
          // setSearchValueSmall(res.data.body.result.data.location);
          setName(res.data.body.result.data.status);
          setCenter({
            lat: +res.data.body.result.data.map_centere.latitude,
            lng: +res.data.body.result.data.map_centere.longitude,
          });
          const calculatedTotalPages = Math.ceil(
            res.data.body.result.total / perPage
          );
          setTotal(calculatedTotalPages);
          setLoading(false);
          setDefaultZoom(7.3);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, perPage, selectedValue, selectedValueBig]);

  const handleFind = async () => {
    setSmallInput(false);
    setCurrentPage(1);
    setSelectedValueBig(searchValue);
    setSelectedValue(searchValueSmall);
  };

  const handleInputChange = async (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setSelectedMarker(null);
    if (value != "") {
      setBigStatus(true);
      setSmallStatus(false);
    } else {
      setBigStatus(false);
      setSmallStatus(false);
    }
    try {
      const response = await axios.get(
        `${DealerApi}/user/business/directory/search/name?keyword=${value}`
      );
      setRecommendations(response.data.body.result);
      if (response.data.body.result.length > 0) {
        setShowDropdown(true);
        setNoDataBig(true);
      }else {
        setRecommendBig(true)
      }
    } catch (error) {
    }
  };

  const handleInputSmallChange = async (event) => {
    const { value } = event.target;
    setUserEnteredValue(value); 
    setSearchValueSmall(value);
    setSelectedMarker(null);
    if (value != "") {
      setSmallStatus(true);
      setBigStatus(false);
    } else {
      setSmallStatus(false);
      setBigStatus(false);
    }
    try {
      const response = await axios.get(
        `${DealerApi}/town/search/name?keyword=${value}`
      );
      if(response.data.body.result.length == 0) {
        setRecommendSmall(true);
      } else {
      setRecommendationsSmall(response.data.body.result);
      setShowDropdown(true);
      }
    } catch (error) {
    }
  };

  const convertToUrl = (text) => {
    text = text.trim().toLowerCase();
    text = text.replace(/\s+/g, "-");
    text = text.replace(/(\d+)\.(\d+)/g, "$1-$2");
    text = text.replace(/[^a-z0-9-]/g, "");
    return text;
  };

  const handleBigSearch = (data) => {
    setCurrentPage(1);
    setSearchValue(data.company_name);
    setSelectedValueBig(data.company_name);
    setShowDropdown(false);
    setBigStatus(false);
    setLoading(true);
  };

  const handleSmallSearch = (data) => {
    setCurrentPage(1);
    // setSearchValueSmall(data.name);
    setSelectedValue(data.name);
    setShowDropdown(false);
    setSmallStatus(false);
    setRecommendationClicked(true);
    navigate(`/business_directory/search/s/all/location/${data.slug}`);
    setSearchValueSmall(data.name);
    setUserEnteredValue(data.name)
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const specificDiv = document.getElementById("bigDropdown");
      if (specificDiv && !specificDiv.contains(event.target)) {
        setSmallStatus(false);
        setBigStatus(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [smallStatus, bigStatus]); 

  const handlePageChange = (page) => {
    setSelectedMarker(null);
    setCurrentPage(page);
    paginationRef.current.scrollTop = 0;
  };

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setSmallStatus(false);
    setBigStatus(false);
    const matchedItem = companyName.find((item) => item.id === marker.id);
    if (matchedItem) {
      document.getElementById(marker.id).style.backgroundColor = "#efefef";
      if (previousHoveredMarkerId && previousHoveredMarkerId !== marker.id) {
        const previousHoveredMarkerElement = document.getElementById(
          previousHoveredMarkerId
        );
        if (previousHoveredMarkerElement) {
          previousHoveredMarkerElement.style.backgroundColor = "white";
        }
      }
      setPreviousHoveredMarkerId(marker.id);
      const listElement = document.getElementById("companyNameList");
      listElement.scrollTo({
        top: document.getElementById(marker.id).offsetTop - 225,
        behavior: "smooth",
      });
    }
  };

  const handleMarkerHover = (marker) => {
    setSelectedMarker(marker);
    setSmallStatus(false);
    setBigStatus(false);
    const matchedItem = companyName.find((item) => item.id === marker.id);
    if (matchedItem) {
      document.getElementById(marker.id).style.backgroundColor = "#efefef";
      if (previousHoveredMarkerId && previousHoveredMarkerId !== marker.id) {
        const previousHoveredMarkerElement = document.getElementById(
          previousHoveredMarkerId
        );
        if (previousHoveredMarkerElement) {
          previousHoveredMarkerElement.style.backgroundColor = "white";
        }
      }
      setPreviousHoveredMarkerId(marker.id);

      const listElement = document.getElementById("companyNameList");
      listElement.scrollTo({
        top: document.getElementById(marker.id).offsetTop - 225,
        behavior: "smooth",
      });
    }
  };

  const handleMarkerMouseHover = (marker) => {
    setSelectedMarker(marker);
    setSmallStatus(false);
    setBigStatus(false);
    const matchedItem = companyName.find((item) => item.id === marker.id);
    if (matchedItem) {
      document.getElementById(marker.id).style.backgroundColor = "#efefef";

      if (previousHoveredMarkerId && previousHoveredMarkerId !== marker.id) {
        const previousHoveredMarkerElement = document.getElementById(
          previousHoveredMarkerId
        );
        if (previousHoveredMarkerElement) {
          previousHoveredMarkerElement.style.backgroundColor = "white";
        }
      }
      setPreviousHoveredMarkerId(marker.id);
    }
  };

  const handleLeave = (marker) => {
    setSelectedMarker(null);
    document.getElementById(marker.id).style.backgroundColor = "white";
  };

  const clusterStyles = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    gridSize: 60,
    maxZoom: 15,
    styles: [
      {
        url: icon_marker_small,
        height: 40,
        width: 40,
        textColor: "black",
        textSize: 14,
      },
    ],
  };

  const buildMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={defaultZoom}
        center={center}
        onLoad={(map) => {
        }
      }
      >
        <MarkerClusterer options={clusterStyles}>
          {(clusterer) =>
            companyName.map((item, index) => (
              <Marker
                key={index}
                position={{ lat: +item.user_lat, lng: +item.user_long }}
                clusterer={clusterer}
                icon={marker}
                onMouseOver={() => handleMarkerHover(item)}
                onClick={() => handleMarkerClick(item)}
              />
            ))
          }
        </MarkerClusterer>
        {selectedMarker && (
          <Marker
            key={selectedMarker.id}
            position={{
              lat: +selectedMarker.user_lat,
              lng: +selectedMarker.user_long,
            }}
            onClick={() => handleMarkerClick(selectedMarker)}
            icon={marker_black}
          />
        )}
        {selectedMarker && (
          <InfoWindow
            position={{
              lat: +selectedMarker.user_lat,
              lng: +selectedMarker.user_long,
            }}
            onCloseClick={() => {
              setSelectedMarker(null);
            }}
          >
            <div className="marker-info-win">
              <h1 className="marker-heading">
                <a
                  href={` /business_directory/details/${
                    selectedMarker.id
                  }/${convertToUrl(selectedMarker.company_name)}`}
                >
                  {selectedMarker.company_name}
                </a>
              </h1>
              {selectedMarker.picture && (
                <img
                  className="map-marker-image"
                  src={`${selectedMarker.picture.url.small}`}
                  alt="Company Logo"
                />
              )}
              {selectedMarker.address_one && (
                <p className="map-marker-address">
                  A: {selectedMarker.address_one}
                </p>
              )}
              {selectedMarker.telephone && (
                <p className="map-marker-telephone">
                  T: {selectedMarker.telephone}
                </p>
              )}
              {selectedMarker.email && (
                <p className="map-marker-email">
                  E:{" "}
                  <a
                    href={`mailto:${selectedMarker.email}`}
                    className="info_mail_link"
                  >
                    {selectedMarker.email}
                  </a>
                </p>
              )}
              {selectedMarker.website && (
                <p className="map-marker-website">
                  W:{" "}
                  <a href={selectedMarker.website} className="info_mail_link">
                    {selectedMarker.website}
                  </a>
                </p>
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  const handleBusinessInfo = () => {
    window.location.href="/business_directory/info";
  }

  function highlightMatchingText(inputValue, textToHighlight) {
    const regex = new RegExp(`(${inputValue})`, 'gi');
    const formattedText = textToHighlight.replace(regex, '<b>$1</b>');
    return formattedText;
  }

  useEffect(() => {
    setSelectedItemIndex(0);
  }, [searchValue, recommendations]);
  
  useEffect(() => {
    setSelectedIndex(0);
  }, [searchValueSmall, recommendationsSmall]);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedItemIndex((prevIndex) =>
        prevIndex < recommendations.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedItemIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : 0
      );
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (recommendations.length > 0) {
        const selectedRecommendation = recommendations[selectedItemIndex];
        handleBigSearch(selectedRecommendation);
      } else {
        setSearchValue("No results found!");
        window.location.href = '/ad_inactive';
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < recommendationsSmall.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : 0
      );
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (recommendationsSmall.length > 0) {
        const selectedRecommendation = recommendationsSmall[selectedIndex];
        navigate(`/business_directory/search/s/all/location/${selectedRecommendation.slug}`);
        handleSmallSearch(selectedRecommendation);
        setUserEnteredValue(selectedRecommendation.name); 
      } 
      // else {
      //   setSearchValueSmall("No results found!");
      // }
    }
  };

  const scrollToItem = (index) => {
    const dropdown = document.getElementById('bigDropdown');
    const item = dropdown ? dropdown.children[0].children[index] : null;
    if (item) {
      const itemOffsetTop = item.offsetTop;
      const itemHeight = item.offsetHeight;
      const dropdownScrollTop = dropdown.scrollTop;
      const dropdownHeight = dropdown.offsetHeight;

      if (itemOffsetTop + itemHeight > dropdownScrollTop + dropdownHeight) {
        dropdown.scrollTop = itemOffsetTop + itemHeight - dropdownHeight;
      } else if (itemOffsetTop < dropdownScrollTop) {
        dropdown.scrollTop = itemOffsetTop;
      }
    }
  };

  const scrollToSmallItem = (index) => {
    const dropdown = document.getElementById('bigDropdown');
    const item = dropdown ? dropdown.children[0].children[index] : null;
    if (item) {
      const itemOffsetTop = item.offsetTop;
      const itemHeight = item.offsetHeight;
      const dropdownScrollTop = dropdown.scrollTop;
      const dropdownHeight = dropdown.offsetHeight;

      if (itemOffsetTop + itemHeight > dropdownScrollTop + dropdownHeight) {
        dropdown.scrollTop = itemOffsetTop + itemHeight - dropdownHeight;
      } else if (itemOffsetTop < dropdownScrollTop) {
        dropdown.scrollTop = itemOffsetTop;
      }
    }
  };

  useEffect(() => {
    if (selectedItemIndex >= 0) {
      scrollToItem(selectedItemIndex);
    }
  }, [selectedItemIndex]);

  useEffect(() => {
    if (selectedIndex >= 0) {
      scrollToSmallItem(selectedIndex);
    }
  }, [selectedIndex]);

  return (
    <>
    <Heder name="Business Directory"/>
      <div className="business_body">
        <div className="business_body_search_section">
            <div className="search_section">
              <div className="big_search_div_section">
                <input
                  type="text"
                  className="input_box_big"
                  id="search-input"
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Search for a business or service"
                  onFocus={() => setSmallStatus(false)}
                  autocomplete="off"
                />

                {bigStatus && (
                  <div
                    className="drop_down_big_search"
                    id="bigDropdown"
                    style={{
                      overflowY:
                        recommendations.length > 8 ? "scroll" : "hidden",
                    }}
                  >
                    {showDropdown && recommendations.length > 0 ? (
                      <ul className="recommendations recommendations_big">
                        {recommendations.map((recommendation, index) => (
                          <a
                            href={`/business_directory/details/${
                              recommendation.id
                            }/${convertToUrl(recommendation.company_name)}`}
                            
                          >
                            {" "}
                            <li
                              key={index}
                              onClick={() => handleBigSearch(recommendation)}
                              className={index === selectedItemIndex ? 'selected' : ''}
                            >
                              <div dangerouslySetInnerHTML={{ __html:highlightMatchingText(searchValue,recommendation.company_name) }} />
                           
                            </li>
                          </a>
                        ))}
                      </ul>
                    ) : 
                    recommendBig && (
                      <p className="no_data_found_search" onClick={() => setSearchValue("No results found!")}>No results found!</p>
                    )}
                  </div>
                )}
              </div>

              <div className="small_search_div_section">
                <input
                  placeholder="Town or County"
                  type="text"
                  className="input_box_big"
                  id="search-input"
                  value={userEnteredValue || searchValueSmall}
                  onChange={handleInputSmallChange}
                  onKeyDown={handleKeyUp}
                  onFocus={() => setBigStatus(false)}
                  autocomplete="off"
                />
                <span class="print_btn login_btn map_search_btn" onClick={handleFind}>
                  Find
                </span>

                {smallStatus && (
                  <div
                    className="drop_down_big_search drop_down_small_search"
                    id="bigDropdown"
                    style={{
                      overflowY:
                        recommendationsSmall.length > 8 ? "scroll" : "hidden",
                    }}
                  >
                    {showDropdown && recommendationsSmall.length > 0 ? (
                      <ul className="recommendations">
                        {recommendationsSmall.map((recommendation, index) => (
                          <li
                            key={index}
                            onClick={() => handleSmallSearch(recommendation)}
                            className={index === selectedIndex ? 'selected' : ''}
                          >
                            <div dangerouslySetInnerHTML={{ __html:highlightMatchingText(searchValueSmall,recommendation.name) }} />
                          </li>
                        ))}
                      </ul>
                    ) 
                    : 
                    recommendSmall && (
                      <p className="no_data_found_search" onClick={() => setSearchValueSmall("No results found!")}>No results found!</p>
                    )
                    }
                  </div>
                )}
              </div>
            </div>
            <div className="map_section" id="map">
              {clientId.google_api && 
              <LoadScript googleMapsApiKey={clientId.google_api} googleMapsArgs={{ loading: "async", callback: "buildMap"}}>
                {buildMap()}
              </LoadScript>}
              <div
                className="join-directory-btn"
                onClick={handleBusinessInfo}
              >
                <p className="join-directory">
                <span className="span_data"> Join our directory{" "}
                  and see <strong>your business</strong> listed here{" "}
                </span>
                  <span className="greater_than"><FaGreaterThan /></span>
                </p>{" "}
              </div>
          </div>
        </div>

        <div className="business_body_right_section">
          {loading ? (
            <p className="result_section">Loading...</p>
          ) : (
            <div className="result_section">{name}</div>
          )}
          <div
            id="companyNameList"
            className="listing_company"
            ref={paginationRef}
            style={{
              overflowY: companyName.length > 12 ? "scroll" : "hidden",
            }}
          >
            <ul>
              {companyName.map((item, index) => (
                <a
                  href={`/business_directory/details/${item.id}/${convertToUrl(
                    item.company_name
                  )}`}
                >
                  <li
                    key={index}
                    id={item.id}
                    onMouseOver={() => handleMarkerMouseHover(item)}
                    onMouseLeave={() => handleLeave(item)}
                    className=""
                  >
                    <IoLocationSharp className="location_svg" />{" "}
                    {item.company_name}
                  </li>
                </a>
              ))}
            </ul>
          </div>
          {(companyName.length > 0) && (total > 1) && (
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                totalPages={total}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BusinessDirectory;
