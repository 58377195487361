import React, { useEffect, useState } from "react";
import "./ImageUpload.css";
import Footer from "./Footer";
import { AiTwotonePicture } from "react-icons/ai";
import { FaArrowsRotate } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button, Table } from "react-bootstrap";
import axios from "axios";
import { DealerApi } from "./Api";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heder from "./Heder";

const ImageUpload = () => {
  const { listing_id } = useParams();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const listing = Cookies.get("listingToken");
  const listingObject = listing && JSON.parse(listing);
  const [images, setImages] = useState([]);
  const [rotationDegrees, setRotationDegrees] = useState([]);
  const [listImages, setListImages] = useState([]);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [load, setLoad] = useState(true);

  const navigate = useNavigate();

  const handleDivClick = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    handleListing();
  }, []);

  const handleListing = () => {
    setLoad(true);
    if (listingObject) {
      axios
        .get(
          `${DealerApi}/listing/image/${
            listingObject && listingObject.listing_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${tokenObject && tokenObject.token}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          setListImages(res.data.body.result);
          setApiDataLoaded(true);
        })
        .catch((err) => {
          setLoad(false);
        });
    }
  };

  const handleImageChange = (event) => {
    setLoad(true);
    const files = event.target.files;
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImage = {
          src: e.target.result,
          alt: `Image ${i + 1}`,
        };

        const formData = new FormData();
        formData.append("listing_id", listingObject.listing_id);
        formData.append("image", file);
        if (listing_id) {
          formData.append("upload_from", "edit");
        } else {
          formData.append("upload_from", "add");
        }
        axios
          .post(`${DealerApi}/listing/image/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${tokenObject.token}`,
            },
          })
          .then((response) => {
            newImages.push(newImage);
            setImages((prevImages) => [...prevImages, newImage]);
            setRotationDegrees((prevRotationDegrees) => [
              ...prevRotationDegrees,
              0,
            ]);
            handleListing();
          })
          .catch((error) => {});
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRotate = (index, rotate) => {
    const image_id = listImages[index].id;
    if (rotate < 270) {
      rotate += 90;
    } else {
      rotate = 0;
    }
    handleRotateAPI(image_id, rotate);
  };

  const handleRotateAPI = (image_id, rotationDegree) => {
    axios
      .patch(
        `${DealerApi}/listing/image/rotate`,
        { image_id: image_id, rotate: rotationDegree },
        {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        handleListing();
      })
      .catch((error) => {});
  };

  const handleRemove = (index) => {
    setLoad(true);
    const imageIdToRemove = listImages[index].id;
    axios
      .delete(
        `${DealerApi}/listing/image/delete/${listingObject.listing_id}/${imageIdToRemove}`,
        {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        }
      )
      .then((response) => {
        // window.location.reload();
        const newImages = [...images];
        const newRotationDegrees = [...rotationDegrees];
        newImages.splice(index, 1);
        newRotationDegrees.splice(index, 1);

        setImages(newImages);
        setRotationDegrees(newRotationDegrees);

        handleListing();
      })
      .catch((error) => {});
  };

  const handleSubmit = () => {
    navigate("/my_online_ads");
  };

  const handlePrevious = () => {
    navigate("/online_ad");
  };

  useEffect(() => {
    if (!listingObject) {
      navigate("/online_ad");
    } else {
      setApiDataLoaded(true);
    }
  }, [apiDataLoaded, listingObject]);

  return (
    <>
    <Heder name= "online ad form" />
      {!listingObject ? null : (
        <>
          <div className="advert_main_div">
            <p className="advert_heading">Upload Picture</p>
          </div>
          <div className="container">
            <div className="upload_image_div">
              <div className="upload_image_content">
                <AiTwotonePicture className="photo_icon" />
                <div>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    multiple
                  />
                  <button
                    type="button"
                    className="add_photo"
                    onClick={handleDivClick}
                  >
                    Add Photos
                  </button>
                </div>
              </div>

              <div className="load_img_div">
                {load && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    className="loader_img"
                  />
                )}
              </div>
              {listImages &&
                listImages.map((image, index) => (
                  <div key={index}>
                    <Table striped>
                      <tbody>
                        <tr>
                          <td className="img_tab">
                            <img
                              src={image.picture.url.small}
                              alt="img"
                              style={{
                                transform: `rotate(${image.rotate}deg)`,
                              }}
                              className="user_upload_img"
                            />
                          </td>
                          <td>
                            <div className="rotate_btn_div">
                              <Button
                                variant="success"
                                title="Rotate"
                                onClick={() =>
                                  handleRotate(index, image.rotate)
                                }
                              >
                                <FaArrowsRotate />
                              </Button>
                              <Button
                                variant="danger"
                                title="Delete"
                                onClick={() => handleRemove(index)}
                              >
                                <RiDeleteBin6Line />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}

              <div className="prev_div">
                <button
                  type="button"
                  className="prev_btn"
                  onClick={handlePrevious}
                >
                  Previous Page
                </button>
                <button
                  type="button"
                  className="prev_btn"
                  onClick={handleSubmit}
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ImageUpload;
