import React, { useState } from "react";
import Footer from "../Component/Footer";
import "./Contact.css";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import { FaPhoneAlt, FaRegUser } from "react-icons/fa";
import { IoIosCreate, IoMdMail } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import { Toast } from "react-bootstrap";
import Heder from "../Component/Heder";

const Contact = () => {
  const initialValues = {
    name: "",
    email: "",
    telephone: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [toastMessage, setToastMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  }

  const validateForm = () => {
    let errors = {};

    if (!formValues.name) {
      errors.name = "Name is required";
    }

    if (!formValues.email) {
      errors.email = "Email is required";
    }

    if (!formValues.telephone) {
      errors.telephone = "Number is required";
    } 
    
    if (!formValues.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
    axios
      .post(`${DealerApi}/contact`, formValues, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const messageFromApi = res.data.body.message;
          setToastMessage(messageFromApi);
          setFormValues(initialValues);
          setTimeout(() => {
            setToastMessage("");
          }, 5000);
        }
      })
      .catch((error) => {
      });
    }
    else {
      setFormErrors(errors);
    }
  };

  return (
    <>
    <Heder name="Contact"/>
      <div className="faq_main_div">
        <p className="faq_heading">Contact Us</p>
      </div>

      <div className="container">
        <div className="row">
          <Toast
              show={toastMessage !== ""}
              onClose={() => setToastMessage("")}
              className="fade bg-success contact_toast_message_div"
            >
              <Toast.Body className="toast_message">{toastMessage}</Toast.Body>
            </Toast>
          <div className="col-lg-8">
            <form onSubmit={handleSubmit}>
              <div className="contact_form">
              <h5 className="contact_title">Get In Touch</h5>
                <div className="row">
                  <div className="col-lg-6 mt-3 contact_main_div">
                    <input
                      type="text"
                      name="name"
                      value={formValues.name}
                      placeholder="Name"
                      className="contact_input"
                      onChange={handleChange}
                    />
                    <FaRegUser className="user_icon" />
                    <div className="error-message">
                      {formErrors.name}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3 contact_main_div">
                    <input
                      type="email"
                      name="email"
                      value={formValues.email}
                      placeholder="Email"
                      className="contact_input"
                      onChange={handleChange}
                    />
                    <MdOutlineEmail className="user_icon" />
                    <div className="error-message">
                      {formErrors.email}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3 contact_main_div">
                    <input
                      type="number"
                      name="telephone"
                      value={formValues.telephone}
                      placeholder="Telephone"
                      className="contact_input"
                      onChange={handleChange}
                    />
                    <MdOutlinePhone className="user_icon" />
                    <div className="error-message">
                      {formErrors.telephone}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3 mb-3 contact_main_div">
                    <textarea
                      name="message"
                      value={formValues.message}
                      placeholder="Message"
                      className="contact_input msg_input"
                      onChange={handleChange}
                    ></textarea>
                    <IoIosCreate className="user_icon" />
                    <div className="error-message">
                      {formErrors.message}
                    </div>
                  </div>
                  <div>
                    <button type="submit" className="send_message">Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4">
          <div className="contact_toast_msg"></div>
            <div className="contact_info">

              <h5 className="contact_title">R.O.I Office</h5>
              <div className="contact_info_div">
                <span className="contact_info_icon">
                  <IoLocation />
                </span>{" "}
                <span className="contact_info_para">
                  Webtown House Port Road Letterkenny Co. Donegal F92 W5HX{" "}
                </span>
              </div>
              <div>
                <a href="mailto:info@thedealer.ie" className="get_in_touch_div">
                  <span className="contact_info_icon">
                    <IoMdMail />
                  </span>{" "}
                  <span classname="contact_info_para">info@thedealer.ie</span>
                </a>
              </div>
              <div>
                <a href="tel:0749126410" className="get_in_touch_div">
                  <span className="contact_info_icon">
                    <FaPhoneAlt />
                  </span>{" "}
                  <span className="contact_info_para">074 912 6410</span>
                </a>
              </div>
              <a href="tel:074 912 8764" className="get_in_touch_div">
                <span className="contact_info_icon">
                  <TbDeviceLandlinePhone />
                </span>{" "}
                <span className="contact_info_para">074 912 8764</span>
              </a>
              <div className="mb-4">
                Text TheDealer to 51444 followed by a space and your ad details.
              </div>

              <hr />

              <div className="mt-4">
                <h5 className="contact_title">N.I Office</h5>
                <div className="contact_info_div">
                  <span className="contact_info_icon">
                    <IoLocation />
                  </span>{" "}
                  <span className="contact_info_para">
                    47 Oakbridge Park Lenamore Derry, Co. L'Derry Derry BT48 8PY
                  </span>
                </div>
                <div>
                  <a href="mailto:info@thedealer.ie" className="get_in_touch_div">
                    <span className="contact_info_icon">
                      <IoMdMail />
                    </span>{" "}
                    <span classname="contact_info_para">info@thedealer.ie</span>
                  </a>
                </div>
                <a href="tel:02871260088" className="get_in_touch_div">
                  <span className="contact_info_icon">
                    <FaPhoneAlt />
                  </span>{" "}
                  <span className="contact_info_para">028 71 260088</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;