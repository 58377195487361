import React, { useState } from "react";
import "./Login.css";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { DealerApi } from "./Api";
import { Alert } from "react-bootstrap";
import Cookies from "js-cookie";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Heder from "./Heder";

const Login = ({onLogin}) => {

  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    emailOrUsername: "",
    password: "",
  };
  const [loginformValues, setLoginFormValues] = useState(initialValues);
  const [loginformErrors, setLoginFormErrors] = useState({});
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginFormValues({ ...loginformValues, [name]: value });
    setLoginFormErrors({ ...loginformErrors, [name]: "" });
  };

  const validateForm = () => {
    let errors = {};
    if (!loginformValues.emailOrUsername) {
      errors.emailOrUsername = "Email or User Name is required";
    }
    if (!loginformValues.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      axios
        .post(`${DealerApi}/auth/login`, loginformValues, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoginFormValues(initialValues);
          if (res.status === 200) {
            onLogin();
            Cookies.set("loginToken", JSON.stringify(res.data.body.result));
            setSuccess(res.data.body.message);
            setError(null);
            if(res.data.body.result.user_type == "admin") {
              window.open(`${process.env.REACT_APP_ADMIN_APP_API_URL}/dashboard`, "_self");
            } else {
              navigate("/client");
            }
            setSuccess(null);
          }
        })
        .catch((error) => {
          setSuccess(null);
          setError(error.response.data.body.message);
          setTimeout(() => {
            setError(null);
          }, 5000);
        });
    } else {
      setLoginFormErrors(errors);
    }
  };

  const handleForgotPswd = () => {
    navigate("/login/forgot-password");
  };

  const handleCookies = () => {
    navigate("/cookies");
  }

  const handleContact = () => {
    navigate("/contact");
  }
  
  return (
    <>
    <Heder name="login" />
      <div className="advert_main_div">
        <p className="advert_heading">Login</p>
      </div>

      <div className="container">
        <div className="login_main">
          <div className="login_form">
            {success && (
              <Alert variant="success" style={{ fontSize: "20px" }}>
                {success}
              </Alert>
            )}
            {error && (
              <Alert variant="danger" style={{ fontSize: "20px" }}>
                {error}
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <label className="login_label">
              Email or Username <span className="astrick">*</span>
              </label>
              <input
                type="text"
                className="login_input"
                name="emailOrUsername"
                value={loginformValues.emailOrUsername}
                onChange={handleChange}
              />
              <div className="error-message">{loginformErrors.emailOrUsername}</div>

              <label className="login_label user_name">
                Password <span className="astrick">*</span>
              </label>
              <div className="password_input">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={loginformValues.password}
                  onChange={handleChange}
                  className="login_input"
                />
                <div
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                </div>
              </div>

              <div className="error-message">{loginformErrors.password}</div>

              <div className="link_div">
                <p className="forgot_pswd">
                  Forgot your{" "}
                  <span className="forget_username" onClick={handleForgotPswd}>
                    username
                  </span>{" "}
                  or{" "}
                  <span className="forget_username" onClick={handleForgotPswd}>
                    password
                  </span>
                  ?
                </p>
              </div>

              <div className="submit_login">
                <button type="submit" className="login_btn">
                  Sign In
                </button>
              </div>
            </form>
            <p className="login_problem">Problems logging in?</p>
            <p className="cookies">
              We use cookies for login authentication. If you are having
              problems logging in you may have to clear your cookies. Read how
              to{" "}
              <span className="forget_username" onClick={handleCookies}>
                clear your cookies.
              </span>{" "}
            </p>
            <p className="cookies">
            If you are still having trouble please{" "}
              <span className="forget_username" onClick={handleContact}>
              Contact us Now.
              </span>{" "}
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Login;
