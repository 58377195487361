import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { DealerApi } from './Api';
import { Helmet } from 'react-helmet';

const Heder = ({name}) => {

    const [title, setTitle] = useState([]);
    const [description, setDescription] = useState([]);
    const [keywords, setKeywords] = useState([]);
  
    useEffect(() => {
      axios
        .get(`${DealerApi}/settings`)
        .then((res) => {
          setTitle(res.data.body.result[0].meta_title);
          setDescription(res.data.body.result[0].meta_keywords);
          setKeywords(res.data.body.result[0].meta_description);
        })
        .catch((err) => {});
    }, []);


  return (
    <>
    <Helmet>
        <title>{`${name.charAt(0).toUpperCase() + name.slice(1)} - ${title}`}</title>
        <meta
          name="description"
          content={`${name} - ${description}`}
        />
        <meta
          name="keywords"
          content={`${name} - ${keywords}`}
        />
      </Helmet>
    </>
  )
}

export default Heder